import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { QueueItems } from "./QueueItems";
import { SortableHandle } from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        sortWrapper: {
            diplay: "flex",
            alignItems: "center",
            width: "80%",
        },
        editWrapper: {
            diplay: "flex",
            alignItems: "center",
            width: "90%",
        },
        queueWrapper: {
            display: "flex",
            width: "100%",
        },
        iconsWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "10%",
        },
        contentWrapper: {
            display: "flex",
            width: "100%",
        },
        deleteIcon: {
            fontSize: "22px",
        },
        dragWrapper: {},
        dragIcon: {
            fontSize: "20px",
            cursor: "pointer",
            color: `${theme.palette.common.black}`,
        },
    }),
);

export interface QueueProps extends InternalStandardProps<GridProps> {
    isDownloading: boolean;
    isShowProgress: boolean;
    isDisableControls: boolean;
    isEdit: boolean;
    type: string;
    name: string;
    nid: string;
    key: string;
    isSortable: boolean;
    handleDeleteItem: (nid: string) => void;
}

export const Queue = (props: QueueProps) => {
    const classes = useStyles(props);
    const {
        className,
        isShowProgress,
        isDownloading,
        type,
        isEdit,
        name,
        key,
        nid,
        isSortable,
        handleDeleteItem,
        isDisableControls,
        ...rest
    } = props;

    return (
        <Grid className={classes.contentWrapper} key={key} {...rest}>
            {isEdit && isSortable && (
                <Box className={classes.iconsWrapper}>
                    <DragHandle />
                </Box>
            )}
            <Box
                className={
                    isEdit
                        ? isSortable
                            ? classes.sortWrapper
                            : classes.editWrapper
                        : classes.queueWrapper
                }
            >
                <QueueItems
                    isShowProgress={isShowProgress}
                    isDownloading={isDownloading}
                    contentType={type}
                    contentTitle={name}
                />
            </Box>

            {isEdit && (
                <>
                    <Box className={classes.iconsWrapper}>
                        <IconButton>
                            <AppDownloadingIcons
                                iconType="trash"
                                onClick={() => handleDeleteItem(nid)}
                            />
                        </IconButton>
                    </Box>
                </>
            )}
        </Grid>
    );
};

const DragHandle = SortableHandle((props) => {
    const classes = useStyles(props);

    return (
        <Box
            onClick={(e) => e.stopPropagation()}
            className={classes.dragWrapper}
        >
            <FontAwesomeIcon
                className={classes.dragIcon}
                icon={["fas", "bars"]}
            ></FontAwesomeIcon>
        </Box>
    );
});
