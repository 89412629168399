import { config } from "JS/Config";
import {
    CourseDetail,
    CourseStep,
    DownloadedCourseDetail,
    FBFunctionStepStats,
    LogFirebaseEventDownloadOptions,
    QueueItem,
    QueueItemContentType,
    StepContentType,
} from "JS/Models";
import { enqueueItem } from "./ContentDownloadHelper";
import { courseStepItemTypeNameToQueueType } from "./Helpers";

export const checkIfStepDownloaded = (
    step: CourseStep,
    downloadedSteps: CourseStep[],
) => {
    const downloaded = downloadedSteps.find((d) => d.nid === step.nid);

    return downloaded ? true : false;
};

export const getDownloadedSteps = (downloaded: DownloadedCourseDetail) => {
    if (downloaded) {
        return downloaded.steps.filter((d) => !!d.isDownloaded);
    }

    return [];
};

export const markAllQuizzesAsDownloaded = (
    steps: CourseStep[],
): CourseStep[] => {
    if (steps) {
        return steps.map((s) => ({
            ...s,
            isDownloaded: s.content_item_type_name === "quiz" ? true : false,
        }));
    }

    return [];
};

export const findStepsToDownload = (
    courseToDownload: DownloadedCourseDetail,
    downloaded: DownloadedCourseDetail,
) => {
    if (!downloaded) {
        return courseToDownload.steps;
    } else {
        const downloadedStepsIds = downloaded.steps
            .filter((s) => s.isDownloaded)
            .map((d) => d.sku_id);

        return courseToDownload.steps.filter(
            (d) => !downloadedStepsIds.includes(d.sku_id),
        );
    }
};

export const enqueueSingleStep = (
    course: DownloadedCourseDetail,
    step: CourseStep,
) => {
    const queueItem: QueueItem = {
        media_url_prefix: step.media_data[0].media.media_url_prefix,
        media_url_postfix: step.media_data[0].media.media_url_postfix,
        media_file_name: step.media_data[0].media.media_file_name,
        name: step.title,
        nid: getCombinedCrsStepNid(course.nid, step.nid),
        type: courseStepItemTypeNameToQueueType(step.content_item_type_name),
        status: "queued",
        skuId: step.sku_id,
        description: step.description,
        tries: 0,
        courseNid: course.nid,
        release_date: course.releaseDate,
        courseSkuId: course.sku_id,
        courseType: course.content_item_type_name,
    };

    enqueueItem(queueItem);
};

export const enqueueSteps = (
    course: DownloadedCourseDetail,
    steps: CourseStep[],
) => {
    const mediaSteps = sortStepsInDetail(steps).filter(
        (d) => d.content_item_type_name !== "quiz",
    );
    for (const step of mediaSteps) {
        enqueueSingleStep(course, step);
    }
};

export const findActiveDownloadedStepAndCourseBySku = (
    courseSku: CourseDetail["sku_id"],
    stepSku: CourseStep["sku_id"],
    downloadedCourses: DownloadedCourseDetail[],
) => {
    if (courseSku && stepSku && downloadedCourses) {
        const activeDownloadedCourse = downloadedCourses.find(
            (x) => x.sku_id === courseSku,
        );
        if (activeDownloadedCourse) {
            const activeDownloadedStep = activeDownloadedCourse.steps?.find(
                (x) => x.sku_id === stepSku,
            );

            return { activeDownloadedStep, activeDownloadedCourse };
        }
    }

    return { activeDownloadedStep: null, activeDownloadedCourse: null };
};

export const getIndexDBIdsForAllSteps = (
    memberId: string,
    crsNid: CourseDetail["nid"],
    steps: CourseStep[],
) => {
    if (steps && crsNid) {
        return steps.map((s) => createCourseIDBId(memberId, crsNid, s.nid));
    }

    return [];
};

export const mutateCourseFirebaseEventDetail = (
    firebaseEventDetails: Partial<LogFirebaseEventDownloadOptions>,
    queueItem: QueueItem,
) => {
    firebaseEventDetails.nId = queueItem.courseNid;
    firebaseEventDetails.stepNId = extractStepIdFromCombinedNid(queueItem.nid);
    firebaseEventDetails.stepSkuId = queueItem.skuId;
    firebaseEventDetails.skuId = queueItem.courseSkuId;
};

export const getCombinedCrsStepNid = (
    courseNid: CourseDetail["nid"],
    stepNid: CourseStep["nid"],
) => {
    return `${courseNid}-${stepNid}`;
};

export const extractStepIdFromCombinedNid = (id: QueueItem["nid"]) => {
    return id.split("-")[1];
};

export const createCourseIDBId = (
    memberId: string,
    courseNid: string,
    stepNid: string,
) => {
    return `${memberId}-${courseNid}-${stepNid}`;
};

export const createCourseIDForStats = (skuId: string) => {
    return `${config.user.memberId}@${skuId}`;
};

export const coursesQueueItemArr: QueueItemContentType[] = [
    "courseMP3",
    "coursePdf",
    "courseVideo",
];

export const checkIsCourseType = (type: QueueItemContentType) => {
    return coursesQueueItemArr.includes(type);
};

export const sortStepsInDetail = (steps: CourseStep[]) => {
    return [...steps].sort((a, b) => +a.position - +b.position);
};

export const createStepObjectForFB = (
    step: CourseStep,
    durationToLog?: number,
    playerTime?: number,
): Partial<FBFunctionStepStats> => ({
    content_type: step.content_item_type_name as StepContentType,
    title: step.title,
    durationToLog: durationToLog,
    playerTime: playerTime,
});
