import { config } from "JS/Config";
import { BaseService } from "../BaseService";
import { FycStatus } from "JS/Models/FycStatus";
import { AppResponse } from "JS/Types";

export class FycStatusService extends BaseService {
    async getFycStatus() {
        return (
            await this.doXHR<AppResponse<FycStatus>>({
                url: this?.routes?.server?.api?.fycStatus?.getFycStatus(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
