import { useEffect, useState } from "react";
import { Box, Grid, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import icEvent from "../../../Images/Content/ic_event.gif";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "./Progress/AppBackdropProgress";

export interface userImageProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    isImageExists: boolean;
    backgroundImageUrl: string;
    handleUploadClick: () => void;
    isDefaultBehaviour: boolean;
    setDialogs: React.Dispatch<
        React.SetStateAction<{
            uploadImageDialog: boolean;
            webCamImageDialog: boolean;
        }>
    >;
    dialogs: {
        uploadImageDialog: boolean;
        webCamImageDialog: boolean;
    };
    handleUploadImage: (event: any) => void;
    hdnFileInput: any;
    fetchingImage: boolean;
    isFromFYC?: boolean;
}

export const UserImage = (props: userImageProps) => {
    const classes = useStyles(props);

    const {
        isImageExists,
        backgroundImageUrl,
        handleUploadClick,
        isDefaultBehaviour,
        setDialogs,
        dialogs,
        handleUploadImage,
        hdnFileInput,
        fetchingImage,
        isFromFYC,
    } = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (fetchingImage) {
            setLoading(true);
        }
    }, [fetchingImage]);

    return (
        <>
            <Grid
                container
                className={clsx(classes.root, isFromFYC && classes.paddingLeft)}
            >
                <Grid item xs={12} className={classes.backgroundImageContainer}>
                    {(fetchingImage || loading) && (
                        <AppBackdropProgress
                            open={fetchingImage || loading}
                            backdropText={messaging?.events?.fetchingPhoto}
                        />
                    )}
                    {isImageExists && (
                        <Box className={classes.imageBoxContainer}>
                            <img
                                className={classes.ltdGif}
                                src={icEvent}
                                alt="Not-Found"
                            />
                            <img
                                src={backgroundImageUrl}
                                alt="Not-Found"
                                className={classes.backImageStyle}
                                onLoad={() => setLoading(false)}
                                onClick={
                                    isDefaultBehaviour
                                        ? () =>
                                              setDialogs({
                                                  ...dialogs,
                                                  uploadImageDialog: true,
                                              })
                                        : () => handleUploadClick()
                                }
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
            {!isDefaultBehaviour && (
                <input
                    style={{ display: "none" }}
                    type="file"
                    ref={hdnFileInput}
                    accept="image/png, image/gif, image/jpeg"
                    id="imageUpload"
                    onChange={(event) => handleUploadImage(event)}
                />
            )}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: "center",
            marginTop: theme.spacing(2),
        },
        backgroundImageContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
            marginBottom: "3%",
        },
        backImageStyle: {
            maxWidth: "300px",
            maxHeight: "350px",
            objectFit: "contain",
        },
        imageBoxContainer: {
            position: "relative",
            background: theme.palette.grey["100"],
            padding: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        ltdGif: {
            background: "white",
            position: "absolute",
            width: "80px",
            height: "80px",
            border: `4px solid ${theme.palette.grey[500]}`,
            borderRadius: "20%",
            right: "-10px",
            marginRight: "10px",
            top: "-35px",
        },
        paddingLeft: {
            paddingLeft: theme.spacing(2),
        },

        [theme.breakpoints.up("md")]: {
            backImageStyle: {
                maxWidth: "450px",
                maxHeight: "450px",
            },
        },
    }),
);
