import { config } from "JS/Config";
import {
    CourseStep,
    EventActions,
    EventCategories,
    EventNames,
    GiftsTypes,
    Playlist,
    QueueItemContentType,
    RecommendationBatch,
    StepContentType,
} from "JS/Models";
import { PaymentMethod } from "JS/React/Components/ContentPurchase/PaymentMethodRadio";
import { AudioCategoryTitle } from "JS/React/Scenes/Audios";
import { FavoritesCategories } from "JS/React/Scenes/Favorites";
import { GiftCategories } from "JS/React/Scenes/Gifts";
import { VideoCategoryTitle } from "JS/React/Scenes/Videos";
import moment from "moment-timezone";
import qs from "qs";
import { appConstants } from "./Contants";
import { getDownloadQueueLength } from "./ContentDownloadHelper";
import { checkIsCourseType } from "./CourseHelpers";
import { isAndroid } from "react-device-detect";

export const videoCategoryTitleToString = (title: VideoCategoryTitle) => {
    switch (title) {
        case VideoCategoryTitle.ALL_VIDEOS:
            return "All";
        case VideoCategoryTitle.MY_VIDEOS:
            return "My Videos";
        case VideoCategoryTitle.RECENTLY_PLAYED:
            return "Recently Played";
        case VideoCategoryTitle.SAVED:
            return "Saved";
    }
};

export const audioCategoryTitleToString = (title: AudioCategoryTitle) => {
    switch (title) {
        case AudioCategoryTitle.ALL_AUDIOS:
            return "All";
        case AudioCategoryTitle.MY_AUDIOS:
            return "My Audios";
        case AudioCategoryTitle.RECENTLY_PLAYED:
            return "Recently Played";
        case AudioCategoryTitle.SAVED:
            return "Saved";
        case AudioCategoryTitle.RECOMMENDED:
            return "Recommended for You";
    }
};

export const paymentMethodToString = (method: PaymentMethod) => {
    switch (method) {
        case PaymentMethod.MY_CREDITS:
            return "My Credits";
        case PaymentMethod.CARD:
            return "Card";
    }
};

export const isSufficientCreditAvailable = (
    method: PaymentMethod,
    availableCredits: number,
    quantity: number,
    requiredTokens: number,
) => {
    return {
        isRedeemDisabled:
            method === PaymentMethod.MY_CREDITS &&
            availableCredits < quantity * requiredTokens,
        isInsufficeintCredits:
            method === PaymentMethod.MY_CREDITS &&
            availableCredits < (quantity + 1) * requiredTokens,
    };
};

export const getUserOS = () => {
    let OS = "";
    if (navigator.userAgent.indexOf("Win") !== -1) OS = "OS(Windows)";
    if (navigator.userAgent.indexOf("Mac") !== -1) OS = "OS(Macintosh)";
    if (navigator.userAgent.indexOf("Linux") !== -1) OS = "OS(Linux)";
    if (navigator.userAgent.indexOf("Android") !== -1) OS = "OS(Android)";
    if (navigator.userAgent.indexOf("like Mac") !== -1) OS = "OS(iOS)";

    return OS;
};

export const isDeviceAllowed = () => {
    const os = getUserOS();
    const isIOS = os?.includes("iOS") || os?.includes("Macintosh");
    const isAndroid = os?.includes("Android");
    return isIOS || isAndroid || config.appEnvironment === "stage";
};

export const isAndroidDevice = () => {
    return isAndroid;
};

export const getTimezone = () => {
    return moment().tz(moment.tz.guess()).zoneAbbr();
};
export const giftTypeIdToString = (gift: string) => {
    switch (gift) {
        case "prospect_sku_id":
            return GiftsTypes.PROSPECT;
        case "member_sku_id":
            return GiftsTypes.MEMBER;
        default:
            return "";
    }
};

export const giftTypeStringToId = (gift: string) => {
    switch (gift) {
        case GiftsTypes.PROSPECT:
            return "prospect_sku_id";
        case GiftsTypes.MEMBER:
            return "member_sku_id";
        default:
            return "";
    }
};

export const giftCategoriesToString = (categories: GiftCategories) => {
    switch (categories) {
        case GiftCategories.PURCHASABLE_BY_MEMBERS:
            return "Purchasable Gifts by Members";
        case GiftCategories.PURCHASABLE_BY_PROSPECTS:
            return "Purchase Gifts for Prospects";
        case GiftCategories.SEND_MEMBER:
            return "Send Member Gifts";
        case GiftCategories.RECEIVED_AS_MEMBER:
            return "Received Gifts as Member";
        case GiftCategories.RECEIVED_AS_PROSPECT:
            return "Received Gifts as Prospect";
        case GiftCategories.GIVEN:
            return "Gifts Given";
    }
};

export const favoritesCategoriesToString = (favorites: FavoritesCategories) => {
    switch (favorites) {
        case FavoritesCategories.AUDIOS:
            return "Audios";
        case FavoritesCategories.VIDEOS:
            return "Videos";
        case FavoritesCategories.DOCUMENTS:
            return "Documents";
    }
};

export const secondsToTimeString = (remainingSeconds: number): string => {
    const totalSeconds = remainingSeconds;

    const seconds = (totalSeconds % 60).toFixed(0);
    const minutes = (Math.floor(totalSeconds / 60) % 60).toFixed(0);
    const hours = (Math.floor(totalSeconds / (60 * 60)) % 24).toFixed(0);
    const days = Math.floor(totalSeconds / 86400).toFixed(0);

    return `${+days !== 0 ? `${days} days,` : ``} ${
        +hours !== 0 ? `${hours} hours,` : ``
    } ${
        +minutes !== 0 ? `${minutes} minutes,` : ""
    } ${`${seconds} seconds`}`.trim();
};

export const getDownloadingSpeed = (
    nid: string,
    inProgressNid: string,
    loadedContent: number,
    contentLength: number,
    startTime: number,
) => {
    if (nid === inProgressNid) {
        const timeConsumed = (Date.now() - startTime) / 1000;

        const netSpeed = loadedContent / timeConsumed;
        return `${(loadedContent / 1048576).toFixed(2)} MB of ${(
            contentLength / 1048576
        ).toFixed(2)} MB (${getReadableFileSizeString(netSpeed)})`;
    } else {
        return "";
    }
};

export const getRemainingTime = (
    nid: string,
    inProgressNid: string,
    loadedContent: number,
    contentLength: number,
    startTime: number,
) => {
    if (nid === inProgressNid) {
        const timeConsumed = (Date.now() - startTime) / 1000;

        const netSpeed = loadedContent / timeConsumed;

        const remainingTime = (contentLength - loadedContent) / netSpeed;

        return `${secondsToTimeString(+remainingTime.toFixed(2))} remaining.`;
    } else {
        return "";
    }
};

export const getDownloadFirebaseCategory = (
    category: QueueItemContentType,
    isGiftCourse: boolean,
): EventCategories => {
    const courseCategory = isGiftCourse
        ? EventCategories.GIFT_COURSE_STEP
        : EventCategories.COURSE_STEP;

    const categories = {
        audio: EventCategories.AUDIOS,
        audioBundle: EventCategories.AUDIOS,
        giftAudioBundle: EventCategories.GIFT_AUDIOS,
        video: EventCategories.VIDEOS,
        giftAudio: EventCategories.GIFT_AUDIOS,
        giftVideo: EventCategories.GIFT_VIDEOS,
        document: EventCategories.DOCUMENTS,
        courseMP3: courseCategory,
        coursePdf: courseCategory,
        courseVideo: courseCategory,
    };

    return categories[category] ? categories[category] : categories.audio;
};

export const getDownloadFirebaseEventName = (
    type: QueueItemContentType,
    action: string,
    isGiftCourse?: boolean,
): EventNames => {
    const typesCategories: QueueItemContentType[] = [
        "audio",
        "audioBundle",
        "giftAudioBundle",
        "video",
        "giftAudio",
        "giftVideo",
        "document",
    ];

    const isCourseType = checkIsCourseType(type);

    const isBundle = type === "audioBundle" || type === "giftAudioBundle";

    if (isCourseType) {
        const actionPrefix = isGiftCourse ? "giftCourseStep" : "courseStep";
        return `${actionPrefix}${action}` as EventNames;
    }

    return typesCategories.includes(type)
        ? isBundle
            ? (`${type.split("B")[0]}${action} ` as EventNames)
            : (`${type}${action} ` as EventNames)
        : EventNames.AUDIO_DOWNLOAD_STARTED;
};

export const getReadableFileSizeString = (fileSizeInBytes: number) => {
    let i = -1;
    const byteUnits = [
        " kb/s",
        " Mb/s",
        " Gb/s",
        " Tb/s",
        "Pb/s",
        "Eb/s",
        "Zb/s",
        "Yb/s",
    ];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(2) + byteUnits[i];
};
export const checkHoursPassed = (
    /**in seconds */
    timeToCheck: number,
    hoursToCheck: number,
) => {
    const hours = 60 * 60 * hoursToCheck;

    const hoursAgo = moment().unix() - hours;

    return timeToCheck < hoursAgo;
};

export const restrictRecIdCountAndString = (recIds: number[]) => {
    let toRet = "";

    if (recIds) {
        for (const id of recIds) {
            const str = toRet.length ? `${toRet},${id}` : `${id}`;
            if (str.length <= 100) {
                toRet = str;
            } else {
                break;
            }
        }
    }

    return toRet;
};

export function buildUrlWithQueryParams(baseUrl: string, params: any): string {
    baseUrl = `${baseUrl}?${qs.stringify(params)}`;

    return baseUrl;
}

export function contentItemTypeNameToString(type: string) {
    switch (type) {
        case "MP3":
            return "Audio";
        default:
            return "";
    }
}

export function checkIfBatchIncludesIbos(batch: RecommendationBatch) {
    return batch?.ibos?.some((d) => d?.ibo_id === config.user.memberId);
}

export function checkIfBatchAllowsNotify(batch: RecommendationBatch) {
    return batch?.ibos?.some(
        (d) => d?.ibo_id === config.user.memberId && d?.should_notify,
    );
}

export function recPurchaseAnalyticsActionAndEvent(
    type: "request" | "failed" | "success" | "canceled" | "confirm-cancel",
) {
    switch (type) {
        case "request":
            return {
                name: EventNames.RECOMMENDATION_PURCHASE_REQUESTED,
                action: EventActions.PURCHASE_REQUESTED,
            };
        case "failed":
            return {
                name: EventNames.RECOMMENDATION_PURCHASE_FAILED,
                action: EventActions.PURCHASE_FAILED,
            };
        case "success":
            return {
                name: EventNames.RECOMMENDATION_PURCHASE_SUCCEEDED,
                action: EventActions.PURCHASE_SUCCEEDED,
            };
        case "canceled":
            return {
                name: EventNames.RECOMMENDATION_PURCHASE_CANCELLED,
                action: EventActions.PURCHASE_CANCELLED,
            };
        case "confirm-cancel":
            return {
                name: EventNames.RECOMMENDATION_CONFIRM_PURCHASE_CANCELLED,
                action: EventActions.CONFIRM_PURCHASE_CANCELLED,
            };
        default:
            return {
                name: null,
                action: null,
            };
    }
}

export function recRedeemAnalyticsActionAndEvent(
    type: "request" | "failed" | "success",
) {
    switch (type) {
        case "request":
            return {
                name: EventNames.RECOMMENDATION_REDEEM_REQUESTED,
                action: EventActions.REDEEM_REQUESTED,
            };
        case "failed":
            return {
                name: EventNames.RECOMMENDATION_REDEEM_FAILED,
                action: EventActions.REDEEM_FAILED,
            };
        case "success":
            return {
                name: EventNames.RECOMMENDATION_REDEEM_SUCCEEDED,
                action: EventActions.REDEEM_SUCCEEDED,
            };
        default:
            return {
                name: null,
                action: null,
            };
    }
}

export function recPlayerAnalyticsActionAndEvent(
    type: "played" | "live-stream",
) {
    switch (type) {
        case "played":
            return {
                name: EventNames.RECOMMENDATION_PLAYED,
                action: EventActions.PLAY,
            };
        case "live-stream":
            return {
                name: EventNames.RECOMMENDATION_LIVE_STREAM,
                action: EventActions.LIVE_STREAM,
            };

        default:
            return {
                name: null,
                action: null,
            };
    }
}

export const isBetaEnvironment = () => {
    return config.appEnvironment === "beta";
};

export const isStageEnvironment = () => {
    return config.appEnvironment === "stage";
};

export const isIpad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;

export const courseStepItemTypeNameToQueueType = (
    name: CourseStep["content_item_type_name"],
): QueueItemContentType => {
    switch (name) {
        case "MP3":
            return "courseMP3";
        case "PDF":
            return "coursePdf";
        case "Video":
            return "courseVideo";
        default:
            return "" as QueueItemContentType;
    }
};

export const stringToContentType = (
    contentType: CourseStep["content_item_type_name"],
): StepContentType => {
    switch (contentType) {
        case "MP3":
            return StepContentType.MP3;
        case "Video":
            return StepContentType.Video;
        case "PDF":
            return StepContentType.PDF;
        case "quiz":
            return StepContentType.Quiz;
        default:
            return "" as StepContentType;
    }
};
export const createUrlFromBlob = (blob: Blob) => {
    return URL.createObjectURL(blob);
};

export const checkIfDownloadLimitReached = (toDownloadArrlength: number) => {
    const queueLength = getDownloadQueueLength();
    const downloadingLimit =
        appConstants.downloadingQueue.downloadingQueueLimit;

    if (queueLength + toDownloadArrlength <= downloadingLimit) return false;

    return true;
};

export const createOfflineIDBID = (memberId: string, nid: string) => {
    return `${memberId}-${nid}`;
};

export enum PlayerSeekAction {
    REWIND = "Rewind",
    FORWARD = "Forward",
}

export const invalidContentSize = (contentSize: number): boolean => {
    return !isAndroid
        ? contentSize > config?.downloading?.maxDownloadingSize ||
              contentSize === 0
        : contentSize === 0;
};

export function formatDuration(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    // Format hours, minutes, and seconds with leading zeros if necessary
    const formattedHours = hours > 0 ? String(hours).padStart(2, "0") : "0";
    const formattedMinutes =
        minutes > 0 ? String(minutes).padStart(2, "0") : "0";
    const formattedSeconds = String(seconds).padStart(2, "0");

    if (+formattedHours <= 0) return `${formattedMinutes}:${formattedSeconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function sortPlaylists(playlists: Playlist[]) {
    if (playlists)
        return [...playlists]
            .sort((a, b) => a.playlist_name.localeCompare(b.playlist_name))
            .sort((a, b) => +a.sort_order - +b.sort_order);
    else return [];
}

export const secondsToPlayerDuration = (remainingSeconds: number): string => {
    const totalSeconds = remainingSeconds;

    const seconds = (totalSeconds % 60).toFixed(0);
    const minutes = (Math.floor(totalSeconds / 60) % 60).toFixed(0);
    const hours = (Math.floor(totalSeconds / (60 * 60)) % 24).toFixed(0);

    return ` ${+hours !== 0 ? `${hours}:` : `00:`}${
        +minutes !== 0 ? `${String(minutes).padStart(2, "0")}:` : "00:"
    }${+seconds !== 0 ? `${String(seconds).padStart(2, "0")}` : "00"}`.trim();
};

export const getUserName = (): string => {
    return `${config.user.firstName} ${config.user.lastName}`;
};
