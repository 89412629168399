import { useState, useCallback, useEffect } from "react";
import { UserTeamInfo } from "JS/Models";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { HomeService } from "JS/Services/Home";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import {
    setUserTeamInfo,
    setLandingPageJson,
    decrementTokenRetry,
} from "JS/Redux/Home";

const service = new HomeService();
export const useTeamInformation = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [landingPageJsonloading, setLandingPageJsonLoading] = useState(false);
    const [teamInfo, setTeamInfo] = useState<UserTeamInfo>(null);
    const { mediaEssentials } = useGlobalMediaEssentials();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const userTeamInformation = useAppSelector(
        (state) => state.home.userTeamInformation,
    );

    const landingPageJson = useAppSelector(
        (state) => state.home.landingPageJson,
    );

    const baseHomePagePath = "LandingPage";
    const homePageFileName = "landing_page.json";
    const testHomePageFileName = "test_landing_page.json";
    const getDefaultHomePagePath = () =>
        `${baseHomePagePath}/${homePageFileName}`;
    const getTeamHomePagePath = (teamName: String) =>
        `${baseHomePagePath}/${teamName}/${homePageFileName}`;
    const getTestUserHomePagePath = (teamName: String) =>
        `${baseHomePagePath}/${teamName}/${testHomePageFileName}`;

    const refetch = useCallback(() => {
        setLoading(true);
        const fetchJsonFileForHomePage = (filePath: string) => {
            setLandingPageJsonLoading(true);
            service
                .getLandingPageJsonFile(filePath)
                .then((response) => {
                    setLandingPageJsonLoading(false);
                    dispatch(
                        setLandingPageJson({
                            landingPageJson: response?.Body?.toString(),
                        }),
                    );
                })
                .catch((err) => {
                    setLandingPageJsonLoading(false);
                    console.log(err);
                });
        };

        return service
            .getTeamsStatusForUser(
                mediaEssentials?.allow_fetch_team_status,
                userTeamInformation,
            )
            .then(async (val) => {
                const response = val?.response;

                if (!response?.status) {
                    fetchJsonFileForHomePage(getDefaultHomePagePath());
                } else {
                    const teamInfoData = val?.response?.data;
                    if (teamInfoData) {
                        if (teamInfoData?.isTestUser) {
                            fetchJsonFileForHomePage(
                                getTestUserHomePagePath(teamInfoData?.teamName),
                            );
                        } else {
                            fetchJsonFileForHomePage(
                                getTeamHomePagePath(teamInfoData?.teamName),
                            );
                        }
                        setTeamInfo(teamInfoData);
                        dispatch(
                            setUserTeamInfo({
                                userTeamInformation: teamInfoData,
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                const res = {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) refetch();
    }, [skip]);

    return {
        refetch,
        teamInfo,
        loading,
        userTeamInformation,
        landingPageJsonloading,
        landingPageJson,
    };
};

export const useTokenRetry = () => {
    const dispatch = useAppDispatch();

    return {
        delTokenRetry: useAppSelector((state) => state.home)?.delTokenRetry,
        decrementTokenRetry: () => dispatch(decrementTokenRetry()),
    };
};
