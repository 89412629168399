import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
    InternalStandardProps,
    Theme,
    Typography,
    ImageList,
    ImageListItem,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigation, useError } from "JS/React/Hooks/NavigationEngine";
import { config } from "JS/Config";
import { useTeamInformation } from "JS/React/Hooks/Home";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRedirectToWebCast } from "JS/React/Hooks/MediaEssentials";
import { useWriteEventAttendance } from "JS/React/Hooks/Firebase";
import { IAttendanceDetails } from "JS/Services/Firebase";
import { useGlobalMedia } from "JS/React/Hooks/Media";
import { Layout } from "../Layout";
import { TransactionCompleteDialog } from "JS/React/Components/ContentPurchase/TransactionCompleteDialog";
import {
    isSubscribed,
    openExternalLink,
    openSSOLink,
} from "JS/Helpers/HomePageHelper";

const actionTypes = {
    NAVIGATION: "Navigation",
    SSO: "SSO",
    LINK: "Link",
    ATTENDANCE: "Attendance",
};

export interface HomeProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const Home = (props: HomeProps) => {
    const { className } = props;
    const {
        loading: userLoading,
        landingPageJsonloading,
        landingPageJson,
        teamInfo,
    } = useTeamInformation();
    const { setGlobalHomeStack } = useGlobalNavStack();
    useRedirectToWebCast();
    useSeamlessUpdate();
    const { navigate, showBackdrop, backdropText } = useNavigation();
    const { showError } = useError();
    const loading = userLoading || landingPageJsonloading;
    const [unitOfTile, setUnitOfTile] = useState(window.innerWidth / 2 - 8);
    const { markEventAttendance, attendanceLoading } = useAttendanceHandlers();
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    const detectSize = () => {
        setUnitOfTile(window.innerWidth / 2 - 8);
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [unitOfTile]);

    const isValidJSON = (jsonString) => {
        try {
            const parsedJSON = JSON.parse(jsonString);
            if (Object.keys(parsedJSON).length === 0) {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    };

    const handleTileClick = (e, action) => {
        if (!isSubscribed(action.includedSubscriptions, teamInfo)) {
            setIsError(true);
            setErrorMessage(action.errorMessage);
            return;
        }

        const { actionType } = action;

        switch (actionType) {
            case actionTypes.SSO:
                openSSOLink(action?.place, false);
                break;

            case actionTypes.LINK:
                openExternalLink(action?.url);
                break;

            case actionTypes.NAVIGATION:
                setGlobalHomeStack({ isHomeTrack: true });
                navigate(action);
                break;
            case actionTypes.ATTENDANCE:
                markEventAttendance(action);
                break;
            default:
                showError(`${actionType} is not supported in Navigation`);
        }
    };

    const classes = useStyles({
        ...props,
        landingBackground: isValidJSON(landingPageJson)
            ? JSON.parse(landingPageJson).landingBackgroundColor
            : "#FFFFFF",
    })();

    useLastLoggedInOnFirstRefresh();

    return (
        <Layout>
            {showBackdrop && (
                <AppBackdropProgress open={true} backdropText={backdropText} />
            )}

            <TransactionCompleteDialog
                open={isError}
                dialogMessage={errorMessage}
                dialogTitle={messaging.subscription.errorTitle}
                onClose={() => setIsError(false)}
                isGenericDialog={true}
            />

            <div className={clsx(className, classes.root)}>
                {loading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.home}
                    />
                )}
                {attendanceLoading && (
                    <AppBackdropProgress
                        open={attendanceLoading}
                        backdropText={messaging.loader.markingAttendance}
                    />
                )}
                {!loading && !isValidJSON(landingPageJson) && (
                    <Typography
                        align="center"
                        variant="h6"
                        className={classes.content}
                    >
                        {messaging.common.noItemsFound}
                    </Typography>
                )}
                <ImageList gap={8}>
                    {!loading &&
                        isValidJSON(landingPageJson) &&
                        landingPageJson &&
                        JSON.parse(landingPageJson)?.landingPageTiles?.map(
                            (tile, idx) => {
                                return (
                                    <ImageListItem
                                        cols={
                                            tile.dimensions.width === 1 ? 1 : 2
                                        }
                                        rows={
                                            tile.dimensions.height === 1 ? 1 : 2
                                        }
                                        key={idx}
                                        onClick={(e) =>
                                            handleTileClick(
                                                e,
                                                tile.actionDetails,
                                            )
                                        }
                                        style={{
                                            width: "100%",
                                            height: `${
                                                tile.dimensions.height *
                                                unitOfTile
                                            }px`,
                                            background:
                                                tile.tileBackgroundColor,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <img
                                            src={tile.tileThumbnail}
                                            alt={tile?.tileThumbnailAlt}
                                            style={{
                                                objectFit: "fill",
                                                width: "100%",
                                                height: "100%",
                                                display: "block",
                                            }}
                                        />
                                    </ImageListItem>
                                );
                            },
                        )}
                </ImageList>
            </div>
        </Layout>
    );
};

const useAttendanceHandlers = () => {
    const { writeEventAttendance, loading } = useWriteEventAttendance();
    const markEventAttendance = (actionDetails: IAttendanceDetails) => {
        writeEventAttendance(actionDetails);
    };
    return { markEventAttendance, attendanceLoading: loading };
};

const useLastLoggedInOnFirstRefresh = () => {
    const { lastLoggedInUser, setLastLoggedInUser } = useGlobalMedia();

    //if user is already logged in and doesn't come from sign in flow after getting the update, this will run to set the lastLoggedInUserId
    useEffect(() => {
        if (!lastLoggedInUser && config.user.memberId) {
            setLastLoggedInUser(config.user.memberId);
        }
    }, [lastLoggedInUser]);
};

const useStyles = (props) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                minHeight: `calc(100vh - ${theme.footer.height})`,
                background: props.landingBackground,
                margin: "2% 0 2% 0",
            },
            backdrop: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            },
            content: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: `calc(100vh - ${theme.footer.height})`,
                width: "100%",
                color: `${theme.palette.common.black}`,
                backgroundColor: `${theme.palette.background.default}`,
            },
        }),
    );
