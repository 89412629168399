import { Theme, InternalStandardProps } from "@mui/material";
import { Layout } from "../Layout";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { DocumentsList } from "./DocumentList";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);

export interface DocumentsProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const Documents = (props: DocumentsProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { homeStack } = useGlobalNavStack();

    const onBackClick = () => {
        if (!homeStack?.isHomeTrack) {
            history.push(linkProvider.react.more().index());
        } else {
            history.push(linkProvider.react.home());
        }
    };

    return (
        <Layout>
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title={"Documents"}
                    canGoBack
                    searchable={false}
                    onBackClick={onBackClick}
                />
                <DocumentsList />
            </div>
        </Layout>
    );
};
