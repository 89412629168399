import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, InternalStandardProps, Theme } from "@mui/material";
import { isIOS } from "react-device-detect";
import { AppButton } from "./AppButton";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Viewer, SpecialZoomLevel, ViewMode } from "@react-pdf-viewer/core";
import { PrintPlugin } from "@react-pdf-viewer/print";
import "./AppDocument.css";

export const AppDocument = (props: AppDocumentProps) => {
    const classes = useStyles(props);
    const { fileUrl, printPlugin, loader, onLoadSuccess = () => {} } = props;

    const { handleOpenDocClick } = useHandlers(props);

    return (
        <>
            {!isIOS ? (
                <React.Fragment>
                    <Viewer
                        renderLoader={() => <>{loader}</>}
                        viewMode={ViewMode.SinglePage}
                        fileUrl={fileUrl}
                        defaultScale={SpecialZoomLevel.PageWidth}
                        onDocumentLoad={onLoadSuccess}
                        pageLayout={{
                            buildPageStyles: () => ({
                                overflow: "hidden",
                            }),
                        }}
                        plugins={[printPlugin]}
                    />
                </React.Fragment>
            ) : (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <FontAwesomeIcon
                        className={clsx(classes.pdfIcon)}
                        icon={["fas", "file-pdf"]}
                    />
                    <AppButton
                        buttonVariant="primary-filled"
                        onClick={handleOpenDocClick}
                    >
                        Open Document
                    </AppButton>
                </Box>
            )}
        </>
    );
};

const useHandlers = (props: AppDocumentProps) => {
    const { fileUrl, onDocumentOpen = () => {} } = props;

    useEffect(() => {
        if (!isIOS) onDocumentOpen();
    }, []);

    const handleOpenDocClick = () => {
        onDocumentOpen();
        window.open(fileUrl, "_blank", "noreferrer");
    };

    return {
        handleOpenDocClick,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        pdfIcon: {
            fontSize: "60px",
            color: "red",
            margin: theme.spacing(2, 0),
        },
    }),
);

export interface AppDocumentProps
    extends Omit<InternalStandardProps<{}>, "ref"> {
    fileUrl: string;
    printPlugin: PrintPlugin;
    loader: React.ReactNode;
    onDocumentOpen?: () => void;
    onLoadSuccess?: () => void;
}
