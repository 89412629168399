import { Theme, InternalStandardProps, Box } from "@mui/material";
import { Layout } from "../Layout";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useHistory, useParams } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import clsx from "clsx";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useGetProfileImage } from "JS/React/Hooks/Events";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { getUserOS, isIpad } from "JS/Helpers";
import { useEffect, useRef, useState } from "react";
import { config } from "JS/Config";
import { UploadImageDialog } from "JS/React/Components/EventsDialog/UploadImageDialog";
import { WebCamImageDailog } from "JS/React/Components/EventsDialog/WebCamImageDailog";
import { useFycStatusDetail } from "JS/React/Hooks/fycStatus";
import moment from "moment";
import { UserRoute } from "JS/Helpers/FycStatusConstants";
import { UserTabs } from "JS/React/Components/UserTabs";
import { UserImage } from "JS/React/Components/UserImage";
import { useImageUpload } from "JS/React/Hooks/Common/ImageUpload";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";

export interface fycStatusProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

const os = getUserOS();

export const FycStatus = (props: fycStatusProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { homeStack } = useGlobalNavStack();
    const { linkProvider } = useRouting();
    const history = useHistory();
    const {
        fycStatus,
        loading: fycStatusLoading,
        refetch: refetchFycStatus,
    } = useFycStatusDetail();
    const hdnFileInput = useRef(null);

    const params: any = useParams();
    const { selectedTab: activeTab } = params;

    const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>("");
    const [dialogs, setDialogs] = useState({
        uploadImageDialog: false,
        webCamImageDialog: false,
    });
    const [isImageExists, setIsImageExists] = useState<boolean>(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [selectedTab, setSelectedTab] = useState({
        isSecondary: false,
        userId: "",
    });

    const { refetch: getImageBlob } = useGetProfileImage(config.user.memberId);

    const {
        uploadImageHandler,
        webCamImageHandler,
        captureUploadHandler,
        closeUploadDialogHandler,
        fetchImageUrl,
        uploadingImage,
        fetchingImage,
    } = useImageUpload();

    useEffect(() => {
        if (activeTab === UserRoute.SECONDARY_USER) {
            setSelectedTab({
                isSecondary: true,
                userId: `${config?.user?.memberId}2`,
            });
        } else {
            setSelectedTab({
                isSecondary: false,
                userId: config?.user?.memberId,
            });
        }
    }, [activeTab]);

    useEffect(() => {
        if (fycStatus?.isComplimentarySubscriber && selectedTab?.userId) {
            fetchImageUrl(getUserId(), setBackgroundImageUrl);
        }
    }, [fycStatus, selectedTab?.userId]);

    useEffect(() => {
        if (backgroundImageUrl) {
            getImageBlob(getUserId()).then((result) => {
                if (!result) {
                    setDialogs({
                        ...dialogs,
                        uploadImageDialog: true,
                    });
                    setIsImageExists(false);
                } else {
                    setIsImageExists(true);
                }
            });
        }
    }, [backgroundImageUrl]);

    const handleCloseUploadDialog = () => {
        closeUploadDialogHandler(
            setDialogs,
            isImageExists,
            linkProvider.react.more().index(),
        );
    };

    const handleUploadImage = (event) => {
        uploadImageHandler(event, getUserId()).then((response) => {
            if (response) {
                fetchImageUrl(getUserId(), setBackgroundImageUrl);
                setDialogs({ ...dialogs, uploadImageDialog: false });
            }
        });
    };

    const handleWebCamImage = () => {
        webCamImageHandler(setDialogs, dialogs);
    };

    const handleCaptureUpload = async () => {
        captureUploadHandler(
            setDialogs,
            dialogs,
            getUserId(),
            capturedImage,
        ).then((res) => {
            if (res) {
                setDialogs({
                    uploadImageDialog: false,
                    webCamImageDialog: false,
                });
            }
            fetchImageUrl(getUserId(), setBackgroundImageUrl);
            setCapturedImage(null);
        });
    };

    const handleUploadClick = () => {
        hdnFileInput.current.click();
    };

    const handleTabSwitch = (userId: string) => {
        history.replace(
            linkProvider.react
                .fycStatus()
                .index(
                    userId === config?.user?.memberId
                        ? UserRoute.PRIMARY_USER
                        : UserRoute.SECONDARY_USER,
                ),
        );
        setSelectedTab({
            isSecondary: userId !== config?.user?.memberId,
            userId: userId,
        });
    };

    const isDefaultBehaviour = isIpad ? false : os?.includes("Macintosh");

    const getUserId = () =>
        selectedTab?.isSecondary
            ? config.user.memberId + "2"
            : config.user.memberId;

    const getUserName = () =>
        selectedTab?.isSecondary
            ? `${fycStatus?.spouseFirstName} ${fycStatus?.spouseLastName}`
            : `${config?.user?.firstName} ${config?.user?.lastName}`;

    const isDateExpired = () => {
        const currentDateTime = new Date();
        const fycExpiryDate = new Date(Number(fycStatus?.expiryDate));

        return fycExpiryDate < currentDateTime;
    };

    const formatStringDate = (stringDate: string) =>
        stringDate
            ? moment(parseInt(stringDate, 10)).format("MM/DD/YY")
            : "---";

    const getFycExpiryDate = () =>
        `FYC Expire${isDateExpired() ? "d" : "s"} On: ${formatStringDate(
            fycStatus?.expiryDate,
        )}`;

    return (
        <Layout>
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title={"My FYC Status"}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.more().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                {!fycStatusLoading && !fycStatus && (
                    <AppConfimationDialog
                        open={true}
                        confirmText="Retry"
                        popupMainText={`${messaging?.fycStatus?.apiFailed}`}
                        onConfirm={() => {
                            refetchFycStatus();
                        }}
                        onClose={() => {
                            history.push(linkProvider.react.more().index());
                        }}
                        showConfirmFirst={true}
                        showButtonBackgroundColor={true}
                        showCloseIcon={true}
                        showCancelButton={false}
                    />
                )}
                {fycStatusLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging?.fycStatus?.fetchingFycStatus}
                    />
                )}
                {!fycStatusLoading && fycStatus && (
                    <RenderIf
                        isTrue={fycStatus?.isComplimentarySubscriber}
                        fallback={
                            <Box className={classes.messageContainer}>
                                <AppTypography
                                    variant="h6"
                                    className={clsx(
                                        classes.greyA100,
                                        classes.message,
                                    )}
                                >
                                    {
                                        messaging?.fycStatus
                                            ?.expiredComplimentaryPlan
                                    }
                                </AppTypography>
                            </Box>
                        }
                    >
                        <>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                flexDirection={"column"}
                                className={classes.textContainer}
                            >
                                <Box>
                                    <AppTypography
                                        className={classes.greyA100}
                                        fontWeight={"bold"}
                                        variant="h4"
                                    >
                                        APPRENTICE
                                    </AppTypography>
                                </Box>

                                <Box>
                                    <AppTypography
                                        className={classes.greyA100}
                                        variant="h6"
                                    >
                                        {`ID: ${getUserId()}`}
                                    </AppTypography>
                                </Box>

                                <Box>
                                    <AppTypography
                                        className={classes.greyA100}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        {getUserName()}
                                    </AppTypography>
                                </Box>
                                <Box>
                                    <AppTypography
                                        className={classes.redFontColor}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        {getFycExpiryDate()}
                                    </AppTypography>
                                </Box>
                            </Box>

                            <UserTabs
                                isSecondaryActive={selectedTab?.isSecondary}
                                handleTabSwitch={handleTabSwitch}
                                hasSpouse={fycStatus?.hasSpouse}
                            />

                            <UserImage
                                isImageExists={isImageExists}
                                backgroundImageUrl={backgroundImageUrl}
                                handleUploadClick={handleUploadClick}
                                isDefaultBehaviour={isDefaultBehaviour}
                                setDialogs={setDialogs}
                                dialogs={dialogs}
                                handleUploadImage={handleUploadImage}
                                hdnFileInput={hdnFileInput}
                                fetchingImage={fetchingImage}
                                isFromFYC={true}
                            />
                        </>
                    </RenderIf>
                )}
                {!uploadingImage && (
                    <UploadImageDialog
                        open={dialogs?.uploadImageDialog}
                        isImageExists={isImageExists}
                        onClose={handleCloseUploadDialog}
                        onUploadImage={handleUploadImage}
                        onWebCamImage={handleWebCamImage}
                        uploadingImage={uploadingImage}
                    />
                )}
                <WebCamImageDailog
                    capturedImage={capturedImage}
                    setCapturedImage={setCapturedImage}
                    open={dialogs?.webCamImageDialog}
                    onClose={handleCloseUploadDialog}
                    onUploadCaptureImage={handleCaptureUpload}
                />

                {uploadingImage && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging?.events?.uploadingPhoto}
                    />
                )}

                {fetchingImage && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging?.events?.fetchingPhoto}
                    />
                )}
            </div>
        </Layout>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        greyA100: {
            color: theme.palette.grey.A100,
        },
        redFontColor: {
            color: theme.palette.error.main,
        },
        textContainer: {
            marginBottom: "15px",
        },
        messageContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
            width: "100%",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        message: {
            textAlign: "center",
        },
    }),
);
