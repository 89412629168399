import { createStyles, makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";

import { AppHeader } from "JS/React/Components/AppHeader";

import { useUserHistory } from "JS/React/Hooks/UserHistory";
import { AppButton } from "JS/React/Components/AppButton";
import { User } from "JS/Models";
import { AppDivider } from "JS/React/Components/AppDivider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100vh",
        },
        grey600: {
            color: theme.palette.grey[600],
        },

        button: {
            position: "fixed",
            bottom: "3%",
            right: "5%",
            left: "5%",
            alignItems: "center",
            justifyContent: "center",
            width: "90%",
        },

        listItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        ListTitle: {
            fontWeight: 400,
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        ListIconsWrapper: {
            width: "5%",
        },
        list: {
            flexGrow: 1,
            paddingBottom: "10%",
        },
        forwardIcon: {},
        box: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            color: theme.palette.common.black,
        },
        list1: {
            paddingBottom: "0px",
            color: theme.palette.common.black,
        },
        list2: {
            paddingTop: "0px",
            paddingBottom: "0px",
            color: theme.palette.common.black,
        },
    }),
);

export const UserHistory = () => {
    const { history: userHistory } = useUserHistory();

    const classes = useStyles();

    const history = useHistory();
    const { linkProvider } = useRouting();
    const { clearData } = useUserHistory();
    const clearHistory = () => {
        clearData();
    };
    const userClick = (user: User) => {
        history.replace(
            linkProvider.react.login(userHistory.indexOf(user).toString()),
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.list}>
                <Box className={classes.box}>
                    <AppHeader
                        title={"User History"}
                        canGoBack
                        searchable={false}
                        onBackClick={() => {
                            history.push(linkProvider.react.login());
                        }}
                    />
                </Box>

                {userHistory &&
                    userHistory.map((a) => (
                        <Box key={a.user_id}>
                            <ListItem
                                className={classes.list1}
                                onClick={() => userClick(a)}
                            >
                                <ListItemText
                                    primary={a.first_name + " " + a.last_name}
                                />
                            </ListItem>
                            <ListItem className={classes.list2}>
                                <ListItemText primary={a.member_id} />
                            </ListItem>
                            <AppDivider />
                        </Box>
                    ))}
            </div>

            <AppButton
                onClick={clearHistory}
                className={classes.button}
                buttonVariant="primary-filled"
            >
                Clear History
            </AppButton>
        </div>
    );
};
