import { useState, useCallback, useEffect } from "react";
import { AppAlternativeResponse, AppResponse } from "JS/Types";
import { sortArrayByKey } from "JS/Helpers";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    AddToCart,
    Gifts,
    GiftsGiven,
    Members,
    GiftsAcquiredDate,
    AcquiredDate,
    GiftRemainingQuantity,
    SendGift,
} from "JS/Models/Media";
import { setGlobalGifts } from "JS/Redux/Gift";
import { EventNames, EventActions, EventCategories } from "JS/Models";
import { useFirebaseLogger } from "./Firebase";
import { GiftService } from "JS/Services/Gift";
import { GiftContentItemTypes } from "../Scenes/Gifts";
import { useGlobalVideos, useSyncOfflineVideos } from "./Video";
import { useGlobalAudios, useSyncOfflineAudios } from "./Audio";
import { config } from "JS/Config";
import { useGiftDashboardSent } from "./Firebase/GiftDashboard";
import { toSentGiftType } from "JS/Models/Firebase/GiftDashboard";

const service = new GiftService();

export const useAudioGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getPurchasableGiftsByMembers("is_audio")

            .then((val) => {
                const response = val?.response;

                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response?.data) {
                        const allGifts = response?.data?.gifts;

                        dispatch(
                            setGlobalGifts({
                                audioGiftsList: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err?.message, {
                    variant: "error",
                });

                const res = {
                    data: {
                        gifts: [] as Gifts[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
    };
};

export const useVideoGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getPurchasableGiftsByMembers("is_video")
            .then((val) => {
                const response = val?.response;

                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response?.data) {
                        const allGifts = response?.data?.gifts;

                        dispatch(
                            setGlobalGifts({
                                videoGiftsList: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                const res = {
                    data: { gifts: [] as Gifts[] },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
    };
};

export const useSentAudioGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);
        return service
            .getSentMediaGifts(false)
            .then((response) => {
                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response.data) {
                        const allGifts = response.data?.gifts;

                        dispatch(
                            setGlobalGifts({
                                sentAudioGiftsList: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err?.message, {
                    variant: "error",
                });

                const res = {
                    data: { gifts: [] as Gifts[] },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
    };
};

export const useSentVideoGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getSentMediaGifts(true)

            .then((response) => {
                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response.data) {
                        const allGifts = response.data?.gifts;

                        dispatch(
                            setGlobalGifts({
                                sentVideoGiftsList: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                const res = {
                    data: {
                        gifts: [] as Gifts[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
    };
};

export const useAudioGiftsGiven = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getMediaGiftsGiven(false)

            .then((response) => {
                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response.data) {
                        const allGifts = response.data?.gifts;

                        dispatch(
                            setGlobalGifts({
                                audioGiftsGiven: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                const res = {
                    data: {
                        gifts: [] as GiftsGiven[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, []);

    return {
        refetch,
        loading,
    };
};

export const useVideoGiftsGiven = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getMediaGiftsGiven(true)
            .then((response) => {
                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response.data) {
                        const allGifts = response.data?.gifts;

                        dispatch(
                            setGlobalGifts({
                                videoGiftsGiven: allGifts,
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                const res = {
                    data: {
                        gifts: [] as GiftsGiven[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, []);

    return {
        refetch,
        loading,
    };
};

export const useReceivedMemberAudioGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [localReceivedMemberAudioGifts, setLocalReceivedMemberAudioGifts] =
        useState<Gifts[]>([]);
    const { receivedMemberAudioGifts } = useGlobalGifts();

    const { playedAudios } = useGlobalAudios();
    const playedAudiosList =
        playedAudios && playedAudios[config.user.memberId]
            ? playedAudios[config.user.memberId]
            : [];

    const { syncOfflineAudios } = useSyncOfflineAudios();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const isGift = useCallback(
        (nid: string) => {
            return !!receivedMemberAudioGifts.find((a) => a.nid === nid);
        },
        [receivedMemberAudioGifts],
    );

    const getGiftDate = useCallback(
        (nid: string) => {
            return receivedMemberAudioGifts.find((g) => g.nid === nid).sender
                .sending_date;
        },
        [receivedMemberAudioGifts],
    );

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getReceivedMemberMediaGifts(false)
            .then((response) => {
                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response.data && response.data?.gifts) {
                        const allGifts: Gifts[] = response.data?.gifts.map(
                            (item) => {
                                if (
                                    playedAudiosList.filter(
                                        (x) => x.nid === item.nid,
                                    ).length > 0
                                ) {
                                    return {
                                        ...item,
                                        played: playedAudiosList.filter(
                                            (x) => x.nid === item.nid,
                                        )[0].played,
                                        isReceived: true,
                                    };
                                } else {
                                    return {
                                        ...item,
                                        isReceived: true,
                                        played: "0",
                                    };
                                }
                            },
                        );
                        syncOfflineAudios(allGifts);
                        const toSave = sortArrayByKey(allGifts, "title", "ASC");
                        setLocalReceivedMemberAudioGifts(toSave);
                        dispatch(
                            setGlobalGifts({
                                receivedMemberAudioGifts: toSave,
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                const res = {
                    data: {
                        gifts: [] as Gifts[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
        loaded,
        isGift,
        getGiftDate,
        localReceivedMemberAudioGifts,
        receivedMemberAudioGifts: useAppSelector((state) => state.gifts)
            ?.receivedMemberAudioGifts,
    };
};

export const useReceivedMemberVideoGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [localReceivedMemberVideos, setLocalReceivedMemberVideos] = useState<
        Gifts[]
    >([]);
    const { receivedMemberVideoGifts } = useGlobalGifts();

    const { playedVideos } = useGlobalVideos();
    const playedVideosList =
        playedVideos && playedVideos[config.user.memberId]
            ? playedVideos[config.user.memberId]
            : [];

    const { syncOfflineVideos } = useSyncOfflineVideos();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const isGift = useCallback(
        (nid: string) => {
            return !!receivedMemberVideoGifts.find((v) => v.nid === nid);
        },
        [receivedMemberVideoGifts],
    );

    const getGiftDate = useCallback(
        (nid: string) => {
            return receivedMemberVideoGifts.find((g) => g.nid === nid).sender
                .sending_date;
        },
        [receivedMemberVideoGifts],
    );

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getReceivedMemberMediaGifts(true)
            .then((response) => {
                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    if (response.data) {
                        const allGifts: Gifts[] = response.data?.gifts.map(
                            (item) => {
                                const playedData =
                                    playedVideosList &&
                                    playedVideosList.find(
                                        (x) => x.nid === item.nid,
                                    );
                                if (playedData) {
                                    return {
                                        ...item,
                                        played: playedData.played,
                                        isReceived: true,
                                    };
                                } else {
                                    return {
                                        ...item,
                                        isReceived: true,
                                        played: "0",
                                    };
                                }
                            },
                        );

                        syncOfflineVideos(allGifts);
                        const toSave = sortArrayByKey(allGifts, "title", "ASC");
                        setLocalReceivedMemberVideos(toSave);
                        dispatch(
                            setGlobalGifts({
                                receivedMemberVideoGifts: toSave,
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                const res = {
                    data: {
                        gifts: [] as Gifts[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
        loaded,
        isGift,
        getGiftDate,
        localReceivedMemberVideos,
        receivedMemberVideoGifts,
    };
};

export const useReceivedProspectAudioGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isSynced, setSynced] = useState<{ synced: boolean }>();
    const { playedAudios } = useGlobalAudios();
    const playedAudiosList =
        playedAudios && playedAudios[config.user.memberId]
            ? playedAudios[config.user.memberId]
            : [];

    const { syncOfflineAudios } = useSyncOfflineAudios();

    const dispatch = useAppDispatch();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getReceivedProspectMediaGifts(false)
            .then((val) => {
                setSynced({ synced: true });
                const response = val?.response;
                if (!!response?.status) {
                    if (response.data && response.data.gifts) {
                        const allGifts: Gifts[] = response.data?.gifts.map(
                            (item) => {
                                if (
                                    playedAudiosList.filter(
                                        (x) => x.nid === item.nid,
                                    ).length > 0
                                ) {
                                    return {
                                        ...item,
                                        played: playedAudiosList.filter(
                                            (x) => x.nid === item.nid,
                                        )[0].played,
                                        isReceived: true,
                                    };
                                } else {
                                    return {
                                        ...item,
                                        isReceived: true,
                                        played: "0",
                                    };
                                }
                            },
                        );
                        syncOfflineAudios(allGifts);

                        dispatch(
                            setGlobalGifts({
                                receivedProspectAudioGifts: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                console.log(err, "err");
                if (err?.response?.status === 403) setSynced({ synced: false });

                const res = {
                    data: {
                        gifts: [] as Gifts[],
                    },
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
        loaded,
        isSynced,
        receivedProspectAudioGifts: useAppSelector((state) => state.gifts)
            ?.receivedProspectAudioGifts,
    };
};

export const useReceivedProspectVideoGifts = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { playedVideos } = useGlobalVideos();
    const playedVideosList =
        playedVideos && playedVideos[config.user.memberId]
            ? playedVideos[config.user.memberId]
            : [];

    const { syncOfflineVideos } = useSyncOfflineVideos();

    const dispatch = useAppDispatch();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getReceivedProspectMediaGifts(true)
            .then(async (val: any) => {
                const response = val?.response;

                if (!!response?.status) {
                    if (response.data && response.data?.gifts) {
                        const allGifts: Gifts[] = response.data?.gifts.map(
                            (item) => {
                                if (
                                    playedVideosList.filter(
                                        (x) => x.nid === item.nid,
                                    ).length > 0
                                ) {
                                    return {
                                        ...item,
                                        played: playedVideosList.filter(
                                            (x) => x.nid === item.nid,
                                        )[0].played,
                                        isReceived: true,
                                    };
                                } else {
                                    return {
                                        ...item,
                                        isReceived: true,
                                        played: "0",
                                    };
                                }
                            },
                        );
                        syncOfflineVideos(allGifts);

                        dispatch(
                            setGlobalGifts({
                                receivedProspectVideoGifts: sortArrayByKey(
                                    allGifts,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                    }
                }

                return response;
            })
            .catch((err) => {
                const res = {
                    data: [],
                    status: err?.toJSON()?.status,
                    message: err?.message,
                };
                return res;
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loading,
        loaded,
        receivedProspectVideoGifts: useAppSelector((state) => state.gifts)
            ?.receivedProspectVideoGifts,
    };
};

export const useSendGifts = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();
    const { logGiftSent } = useGiftDashboardSent();

    const sendGiftToUser = useCallback(
        async (gifts: SendGift[], selectedMembers: Members[]) => {
            setLoading(true);
            try {
                const skuIds = gifts.map((c) => c.sku);
                const val = await service.sendGift(
                    skuIds,
                    selectedMembers.map((s) => s.member_id),
                );
                if (!val.response?.status) {
                    const splits = val.response.message.split("\\n");
                    let messageToShow = splits.reduceRight((p, c, i) => {
                        if (i > 0) return `${c}, ${p}`;
                        else return `${c} ${p}`;
                    });
                    enqueueSnackbar(messageToShow, {
                        variant: "error",
                    });
                } else {
                    gifts.forEach((gift) => {
                        selectedMembers.forEach((member) => {
                            logFirebaseEvent(getEventName(gift.category), {
                                action: EventActions.GIFT_SENT,
                                skuId: gift.sku,
                                receiverId: member.member_id,
                                nId: gift.nid,
                                contentTitle: gift?.title,
                            });
                            logGiftSent(
                                {
                                    sku: gift.sku,
                                    nid: gift.nid,
                                    contentTitle: gift?.title,
                                    receiverFirstName: member.first_name,
                                    receiverLastName: member.last_name,
                                },
                                toSentGiftType(gift.category),
                            );
                        });
                    });

                    enqueueSnackbar(val.response.message, {
                        variant: "success",
                    });
                }
                return val;
            } catch (err) {
                enqueueSnackbar(err.message, { variant: "error" });
                return null as AppResponse<null>;
            } finally {
                setLoading(false);
            }
        },
        [],
    );

    const getEventName = (category: string) => {
        if (category === EventCategories.GIFT_AUDIOS) {
            return EventNames.GIFT_AUDIO_GIFT_SENT;
        }

        if (category === EventCategories.GIFT_VIDEOS) {
            return EventNames.GIFT_VIDEO_GIFT_SENT;
        }

        if (category === EventCategories.GIFT_COURSE) {
            return EventNames.GIFT_COURSE_SENT;
        }
    };

    return {
        sendGiftToUser,
        loading,
    };
};

export const usePurchasableGiftsAcquiredDates = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const getPurchasableGiftsAcquiredDates = useCallback(
        (
            contentType: string,
            pageOffset: number,
            pageSize: number,
            sincePaymentDate: string,
        ) => {
            setLoading(true);

            return service
                .getPurchasableGiftsAcquiredDates(
                    contentType,
                    pageOffset,
                    pageSize,
                    sincePaymentDate,
                )
                .then(
                    (
                        val: AppResponse<{
                            gifts: GiftsAcquiredDate[];
                            pageNo: number;
                            totalPages: number;
                        }>,
                    ) => {
                        if (!val.response?.status) {
                            enqueueSnackbar(val.response.message, {
                                variant: "error",
                            });
                        }
                        const gifts = val.response.data?.gifts;
                        const acquiredDatesObj: AcquiredDate = {};
                        gifts?.forEach(
                            (gift) =>
                                (acquiredDatesObj[gift.sku_id] =
                                    gift.acquired_date),
                        );
                        let acquiredDatesKey = "";
                        if (contentType === GiftContentItemTypes.GIFT_MP3)
                            acquiredDatesKey = "audioAcquiredDates";
                        else if (
                            contentType === GiftContentItemTypes.GIFT_VIDEO
                        )
                            acquiredDatesKey = "videoAcquiredDates";
                        else if (
                            contentType === GiftContentItemTypes.GIFT_COURSE
                        )
                            acquiredDatesKey = "courseAcquiredDates";
                        else if (
                            contentType === GiftContentItemTypes.GIFT_PROS_MP3
                        )
                            acquiredDatesKey = "prosAudioAcquiredDates";
                        else if (
                            contentType === GiftContentItemTypes.GIFT_PROS_VIDEO
                        )
                            acquiredDatesKey = "prosVideoAcquiredDates";
                        dispatch(
                            setGlobalGifts({
                                [acquiredDatesKey]: acquiredDatesObj,
                            }),
                        );
                        return val;
                    },
                )
                .catch((err) => {
                    enqueueSnackbar(err.message, { variant: "error" });
                    return null as AppResponse<null>;
                });
        },
        [],
    );

    const getAllPurchasableGiftsAcquiredDates = async (
        contentType: string,
        since: string,
    ) => {
        let pageOffset = 1;
        const pageSize = 1000;
        let totalPages = 2;
        while (pageOffset <= totalPages) {
            const res = await getPurchasableGiftsAcquiredDates(
                contentType,
                pageOffset,
                pageSize,
                since,
            );
            totalPages = res.response?.data?.totalPages;
            pageOffset++;
        }
    };

    return {
        getAllPurchasableGiftsAcquiredDates,
        loading,
    };
};

export const useAllMembersOfGroup = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState<Members[]>([]);

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .allMembersOfGroup()
            .then((val) => {
                if (val.response?.status) {
                    setMembers(val?.response?.data?.members);
                } else {
                    enqueueSnackbar(val.response?.message, {
                        variant: "error",
                    });
                }
                return val;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, { variant: "error" });
                return null as AppResponse<AddToCart>;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        members,
        refetch,
        loading,
    };
};

export const useGiftRemainingQuantity = (skuId: string, skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [giftQuantity, setGiftQuantity] = useState<number>(0);

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback((skuId) => {
        setLoading(true);
        return service
            .giftRemainingQuantity(skuId)
            .then(async (val) => {
                if (
                    !val?.status &&
                    val?.message !==
                        "No data Found for this SKU ID OR Member ID"
                ) {
                    enqueueSnackbar(val?.message, {
                        variant: "error",
                    });
                } else {
                    setGiftQuantity(
                        val?.data.length > 0 ? val?.data[0]?.quantity : 0,
                    );
                }
                return val;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, { variant: "error" });
                return null as AppAlternativeResponse<GiftRemainingQuantity[]>;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip && skuId) {
            refetch(skuId);
        }
    }, [skip, skuId]);

    return {
        refetch,
        giftQuantity,
        loading,
    };
};

export const useProsGiftRemainingQuantity = (skuId: string, skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [giftQuantity, setGiftQuantity] = useState<number>(0);

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback((skuId) => {
        setLoading(true);
        return service
            .giftProsRemainingQuantity(skuId)
            .then(async (val) => {
                if (
                    !val?.status &&
                    val?.message !==
                        "No data Found for this SKU ID OR Member ID"
                ) {
                    enqueueSnackbar(val?.message, {
                        variant: "error",
                    });
                } else {
                    setGiftQuantity(
                        val?.data.length > 0 ? val?.data[0]?.quantity : 0,
                    );
                }
                return val;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, { variant: "error" });
                return null as AppAlternativeResponse<GiftRemainingQuantity[]>;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip && skuId) {
            refetch(skuId);
        }
    }, [skip, skuId]);

    return {
        refetch,
        giftQuantity,
        loading,
    };
};

export const useSyncDYS = () => {
    const [signingIn, setSigningIn] = useState(false);
    const [error, setError] = useState<{ error: string }>();
    const [success, setSuccess] = useState(false);

    const signIn = (userName: string, password: string) => {
        setSigningIn(true);
        return service
            .syncDYS(userName, password)
            .then((val) => {
                if (val.response.status) setSuccess(true);
                else
                    setError({
                        error: "Something went wrong. Please try again",
                    });
            })
            .catch((e) =>
                setError({
                    error: e
                        ? e.response.data.response.message
                        : "Something went wrong. Please try again",
                }),
            )
            .finally(() => setSigningIn(false));
    };

    return {
        signingIn,
        success,
        error,
        signIn,
    };
};

export const useGlobalGifts = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.gifts);

    return {
        audioGifts: useAppSelector((state) => state.gifts)?.audioGiftsList,
        videoGifts: useAppSelector((state) => state.gifts)?.videoGiftsList,
        sentAudioGifts: useAppSelector((state) => state.gifts)
            ?.sentAudioGiftsList,
        sentVideoGifts: useAppSelector((state) => state.gifts)
            ?.sentVideoGiftsList,
        receivedMemberAudioGifts:
            useAppSelector((state) => state.gifts)?.receivedMemberAudioGifts ||
            [],
        receivedMemberVideoGifts:
            useAppSelector((state) => state.gifts)?.receivedMemberVideoGifts ||
            [],
        receivedProspectAudioGifts: useAppSelector((state) => state.gifts)
            ?.receivedProspectAudioGifts,
        receivedProspectVideoGifts: useAppSelector((state) => state.gifts)
            ?.receivedProspectVideoGifts,
        audioGiftsGiven: useAppSelector((state) => state.gifts)
            ?.audioGiftsGiven,
        videoGiftsGiven: useAppSelector((state) => state.gifts)
            ?.videoGiftsGiven,
        audioAcquiredDates: useAppSelector((state) => state.gifts)
            ?.audioAcquiredDates,
        videoAcquiredDates: useAppSelector((state) => state.gifts)
            ?.videoAcquiredDates,
        courseAcquiredDates: useAppSelector((state) => state.gifts)
            ?.courseAcquiredDates,
        prosAudioAcquiredDates: useAppSelector((state) => state.gifts)
            ?.prosAudioAcquiredDates,
        prosVideoAcquiredDates: useAppSelector((state) => state.gifts)
            ?.prosVideoAcquiredDates,
        setGlobalGifts: (gifts) => {
            dispatch(
                setGlobalGifts({
                    ...allData,
                    ...gifts,
                }),
            );
        },
    };
};
