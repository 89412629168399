import { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Theme, InternalStandardProps } from "@mui/material";
import { Layout } from "../Layout";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useHistory, useParams } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import clsx from "clsx";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    useGetBarCode,
    useGetEvents,
    useGetPdfBlob,
    useGetProfileImage,
    useGlobalEvents,
} from "JS/React/Hooks/Events";
import { config } from "JS/Config";
import moment from "moment";
import { AppButton } from "JS/React/Components/AppButton";
import { getSignedPublicUrl } from "JS/Helpers/S3Helper";
import { UploadImageDialog } from "JS/React/Components/EventsDialog/UploadImageDialog";
import { messaging } from "JS/Helpers/UserMessaging";
import { WebCamImageDailog } from "JS/React/Components/EventsDialog/WebCamImageDailog";
import { getUserOS, isIpad } from "JS/Helpers";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { AppDivider } from "JS/React/Components/AppDivider";
import { useThemeModeContext } from "JS/React/Context/ThemeModeProvider";
import { AppInfoEndpoints } from "../AppInfo";
import { Tabs } from "JS/Helpers/FycStatusConstants";
import { UserTabs } from "JS/React/Components/UserTabs";
import { UserImage } from "JS/React/Components/UserImage";
import { useImageUpload } from "JS/React/Hooks/Common/ImageUpload";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        greyA100: {
            color: theme.palette.grey.A100,
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        barCodeStyle: {
            objectFit: "cover",
            width: "100%",
            height: "80px",
        },
        wrapperDetailContent: {
            justifyContent: "center",
            marginTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        warpperDetailsItem: {
            marginTop: theme.spacing(2),
            display: "inline-flex !important",
            alignItems: "flex-start",
        },
        warpperName: {
            marignTop: `${theme.spacing(2)} !important`,
        },
        btnDownloadPDF: {
            height: "35px",
        },
        ItemWrapper: {
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        disclaimerURL: {
            color: `${theme.palette.primary[900]}`,
            cursor: "pointer",
        },
    }),
);

export interface EventsProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

const os = getUserOS();

export const EventContentDetail = (props: EventsProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const params: any = useParams();
    const { eventName, selectedTab: activeTab } = params;

    const hdnFileInput = useRef(null);

    const { linkProvider } = useRouting();
    const history = useHistory();
    const { homeStack } = useGlobalNavStack();
    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>("");
    const [dialogs, setDialogs] = useState({
        uploadImageDialog: false,
        webCamImageDialog: false,
    });
    const [isImageExists, setIsImageExists] = useState<boolean>(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const { events: eventList } = useGlobalEvents();

    const event = useMemo(() => {
        return eventList?.find((event) => event.EventName === eventName);
    }, [eventList, eventName]);

    const [selectedTab, setSelectedTab] = useState(
        activeTab === Tabs.PRIMARY_USER
            ? event?.Tickets[0]?.UserId
                ? event?.Tickets[0]?.UserId
                : config?.user?.memberId
            : `${config?.user?.memberId}2`,
    );

    const {
        loading: loadingEvents,
        isErrorNoEvents,
        isErrorNoRegistered,
        refetch: getEventsList,
    } = useGetEvents(eventList && eventList.length > 0);

    const filteredTabData = useMemo(() => {
        return event?.Tickets.filter((x) => x.UserId === selectedTab)[0];
    }, [selectedTab, event]);

    const { loading: barCodeLoading, response: barCodeUrl } = useGetBarCode(
        filteredTabData?.UserId,
    );

    const { refetch: getPdfBlob } = useGetPdfBlob(pdfUrl);

    const { refetch: getImageBlob } = useGetProfileImage(
        filteredTabData?.UserId,
    );

    const {
        uploadImageHandler,
        webCamImageHandler,
        captureUploadHandler,
        closeUploadDialogHandler,
        fetchImageUrl,
        uploadingImage,
        fetchingImage,
    } = useImageUpload();

    useEffect(() => {
        if (filteredTabData) {
            getSignedPublicUrl(filteredTabData?.LinkToTicket)
                .then((res) => {
                    setPdfUrl(res);
                })
                .catch((err) => console.log(err));

            fetchImageUrl(filteredTabData?.UserId, setBackgroundImageUrl);
        }
    }, [filteredTabData]);

    useEffect(() => {
        if (backgroundImageUrl) {
            getImageBlob(filteredTabData?.UserId).then((result) => {
                if (!result) {
                    setDialogs({
                        ...dialogs,
                        uploadImageDialog: true,
                    });
                    setIsImageExists(false);
                } else {
                    setIsImageExists(true);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundImageUrl]);

    const handleDownloadPDF = () => {
        window.open(pdfUrl, "_blank");
        getPdfBlob(pdfUrl).then((res) => {
            const fileData = new Blob([res], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(fileData);
            link.download = `LTD Print Event.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
        });
    };

    const handleCloseUploadDialog = () => {
        closeUploadDialogHandler(
            setDialogs,
            isImageExists,
            linkProvider.react.events().index(),
        );
    };

    const handleUploadImage = (event) => {
        uploadImageHandler(event, filteredTabData?.UserId).then((response) => {
            if (response) {
                getEventsList();
                setDialogs({ ...dialogs, uploadImageDialog: false });
            }
        });
    };

    const handleWebCamImage = () => {
        webCamImageHandler(setDialogs, dialogs);
    };

    const handleCaptureUpload = async () => {
        captureUploadHandler(
            setDialogs,
            dialogs,
            filteredTabData?.UserId,
            capturedImage,
        ).then((res) => {
            if (res) {
                setDialogs({
                    uploadImageDialog: false,
                    webCamImageDialog: false,
                });
            }
            getEventsList();
            setCapturedImage(null);
        });
    };

    const handleUploadClick = () => {
        hdnFileInput.current.click();
    };
    const handleTabSwitch = (userId: string) => {
        if (userId === config?.user?.memberId) {
            history.replace(
                linkProvider.react
                    .events()
                    .detail(eventName, Tabs.PRIMARY_USER),
            );
        } else {
            history.replace(
                linkProvider.react
                    .events()
                    .detail(eventName, Tabs.SECONDARY_USER),
            );
        }
        setSelectedTab(userId);
    };

    const isDefaultBehaviour = isIpad ? false : os?.includes("Macintosh");

    const themeModeContext = useThemeModeContext();
    const isDark = themeModeContext.mode === "dark";

    return (
        <Layout>
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title={"My Event Registrations"}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.events().index());
                        } else {
                            history.goBack();
                        }
                    }}
                />
                {uploadingImage && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging?.events.uploadingPhoto}
                    />
                )}
                {!loadingEvents && (
                    <>
                        <UserTabs
                            isSecondaryActive={
                                activeTab === Tabs.SECONDARY_USER
                            }
                            handleTabSwitch={handleTabSwitch}
                            hasSpouse={event?.Tickets?.length > 1}
                        />
                        <Grid
                            container
                            className={classes.wrapperDetailContent}
                        >
                            <Grid item xs={12} md={12}>
                                {!barCodeLoading && (
                                    <>
                                        <img
                                            src={barCodeUrl}
                                            alt="Not-Found"
                                            className={classes.barCodeStyle}
                                        />
                                        <AppTypography
                                            className={classes.greyA100}
                                            variant="body1"
                                        >
                                            {filteredTabData?.UserId}
                                        </AppTypography>
                                    </>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                                marginTop="1%"
                            >
                                <AppTypography
                                    className={classes.greyA100}
                                    fontWeight={"bold"}
                                    variant="h5"
                                >
                                    {filteredTabData?.FirstName}{" "}
                                    {filteredTabData?.LastName}
                                </AppTypography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                                className={classes.grey600}
                            >
                                <AppTypography
                                    fontWeight={"bold"}
                                    color={
                                        isDark
                                            ? filteredTabData?.messageColorDark
                                                ? filteredTabData?.messageColorDark
                                                : filteredTabData?.messageColoriOS
                                            : filteredTabData?.messageColoriOS
                                            ? filteredTabData?.messageColoriOS
                                            : filteredTabData?.messageColorDark
                                    }
                                    variant="h6"
                                >
                                    {filteredTabData?.message}
                                </AppTypography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                                className={classes.grey600}
                            >
                                <AppTypography
                                    fontWeight={"bold"}
                                    color={
                                        isDark
                                            ? filteredTabData?.message2ColorDark
                                                ? filteredTabData?.message2ColorDark
                                                : filteredTabData?.message2ColoriOS
                                            : filteredTabData?.message2ColoriOS
                                            ? filteredTabData?.message2ColoriOS
                                            : filteredTabData?.message2ColorDark
                                    }
                                    variant="h6"
                                >
                                    {filteredTabData?.message2}
                                </AppTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <AppTypography
                                    variant="body1"
                                    className={classes.grey600}
                                >
                                    {event?.EventName}
                                </AppTypography>
                                <AppTypography
                                    variant="caption"
                                    className={classes.grey600}
                                >
                                    {moment(
                                        event?.EventStartDateAndTime.split(
                                            "T",
                                        )[0],
                                    ).format("LL") +
                                        " to " +
                                        moment(
                                            event?.EventEndDateAndTime.split(
                                                "T",
                                            )[0],
                                        ).format("LL")}
                                </AppTypography>
                            </Grid>
                            <UserImage
                                isImageExists={isImageExists}
                                backgroundImageUrl={backgroundImageUrl}
                                handleUploadClick={handleUploadClick}
                                isDefaultBehaviour={isDefaultBehaviour}
                                setDialogs={setDialogs}
                                dialogs={dialogs}
                                handleUploadImage={handleUploadImage}
                                hdnFileInput={hdnFileInput}
                                fetchingImage={fetchingImage}
                            />

                            <Grid item xs={12}>
                                <AppDivider />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.warpperDetailsItem}
                            >
                                <Grid item xs={4}>
                                    <AppTypography
                                        variant="body1"
                                        className={classes.grey600}
                                        fontWeight={"bold"}
                                    >
                                        Member
                                    </AppTypography>
                                </Grid>
                                <Grid item xs={8}>
                                    <AppTypography
                                        variant="body1"
                                        className={classes.grey600}
                                    >
                                        {filteredTabData?.FirstName}{" "}
                                        {filteredTabData?.LastName}
                                    </AppTypography>
                                    <AppTypography
                                        variant="caption"
                                        className={classes.grey600}
                                    >
                                        LTD ID: {filteredTabData?.UserId}
                                    </AppTypography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.warpperDetailsItem}
                            >
                                <Grid item xs={4}>
                                    <AppTypography
                                        variant="body1"
                                        className={classes.grey600}
                                        fontWeight={"bold"}
                                    >
                                        Product
                                    </AppTypography>
                                </Grid>
                                <Grid item xs={8}>
                                    <AppTypography
                                        variant="body1"
                                        className={clsx(
                                            classes.grey600,
                                            classes.ItemWrapper,
                                        )}
                                    >
                                        {filteredTabData?.ProductName}
                                    </AppTypography>
                                    <AppTypography
                                        variant="caption"
                                        className={classes.grey600}
                                    >
                                        SKU: {filteredTabData?.Sku}
                                    </AppTypography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.warpperDetailsItem}
                            >
                                <Grid item xs={4}>
                                    <AppTypography
                                        className={classes.grey600}
                                        fontWeight={"bold"}
                                    >
                                        Amount
                                    </AppTypography>
                                </Grid>
                                <Grid item xs={8}>
                                    <AppTypography
                                        variant="body1"
                                        className={classes.grey600}
                                    >
                                        $ {filteredTabData?.ProductPrice}.00
                                    </AppTypography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.warpperDetailsItem}
                            >
                                <Grid item xs={4}>
                                    <AppTypography
                                        className={classes.grey600}
                                        fontWeight={"bold"}
                                    >
                                        Registration
                                    </AppTypography>
                                </Grid>
                                <Grid item xs={8}>
                                    <AppButton
                                        onClick={handleDownloadPDF}
                                        rounded
                                        buttonVariant="grey-outlined"
                                        className="btnDownloadPDF"
                                    >
                                        Download PDF
                                    </AppButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} marginTop="4%" marginBottom="4%">
                                <AppDivider />
                            </Grid>
                            <Grid item xs={12}>
                                <AppTypography
                                    className={classes.grey600}
                                    variant="body1"
                                >
                                    {messaging?.events?.disclaimer}{" "}
                                    <span
                                        className={classes.disclaimerURL}
                                        onClick={() =>
                                            window.open(
                                                config.feedbackBaseApiUrl +
                                                    AppInfoEndpoints.REGISTRATION_DISCLOURSE,
                                                "_self",
                                            )
                                        }
                                    >
                                        here
                                    </span>
                                    .
                                </AppTypography>
                            </Grid>
                        </Grid>
                    </>
                )}
                {!uploadingImage && (
                    <UploadImageDialog
                        open={dialogs?.uploadImageDialog}
                        isImageExists={isImageExists}
                        onClose={handleCloseUploadDialog}
                        onUploadImage={handleUploadImage}
                        onWebCamImage={handleWebCamImage}
                        uploadingImage={uploadingImage}
                    />
                )}
                <WebCamImageDailog
                    capturedImage={capturedImage}
                    setCapturedImage={setCapturedImage}
                    open={dialogs?.webCamImageDialog}
                    onClose={handleCloseUploadDialog}
                    onUploadCaptureImage={handleCaptureUpload}
                />

                {loadingEvents && !isErrorNoEvents && !isErrorNoRegistered && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging?.events?.fetchingRecords}
                    />
                )}

                {fetchingImage && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging?.events?.fetchingPhoto}
                    />
                )}
            </div>
        </Layout>
    );
};
