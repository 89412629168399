import React, { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faHouse,
    faMusic,
    faVideo,
    faGift,
    faEllipsis,
    faStar as faFilledStar,
    faChevronLeft,
    faChevronRight,
    faPlay,
    faArrowUp,
    faArrowDown,
    faArrowRight,
    faCreditCard,
    faXmark,
    faPlus,
    faMinus,
    faDownload,
    faCircleInfo,
    faCircleCheck,
    faPrint,
    faLock,
    faCameraRotate,
    faAward,
    faPowerOff,
    faEarthAmericas,
    faPenToSquare,
    faTrash,
    faPen,
    faShuffle,
    faSquareMinus,
    faBars,
    faRepeat,
    faMessage,
    faHeadphones,
    faFilePdf,
    faFileArrowDown,
    faTv,
    faPause,
    faUpRightFromSquare,
    faForwardStep,
    faBackwardStep,
    faWindowMinimize,
    faWindowMaximize,
    faRotateRight,
    faCogs,
    faMoon,
    faTablet,
    faTabletAlt,
    faSun,
    faFilter,
    faGlobe,
    faUser,
    faDashboard,
} from "@fortawesome/free-solid-svg-icons";
import {
    faStar,
    faPaperPlane,
    faFolderClosed,
    faFileLines,
    faAddressCard,
    faFloppyDisk,
    faClipboard,
    faSun as farSun,
    faMoon as farMoon,
    faIdBadge,
} from "@fortawesome/free-regular-svg-icons";
import { useRouting } from "./Hooks/Routes";
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import PrivateRoute from "./Container/PrivateRoute";
import { VideosContentListing } from "./Scenes/Videos/VideosContentListing";
import { Videos } from "./Scenes/Videos";
import { Login } from "./Scenes/Login";
import { useAuth } from "./Context/AuthenticationProvider";
import { Audios } from "./Scenes/Audios";
import { Gifts } from "./Scenes/Gifts";
import { Home } from "./Scenes/Home/index";
import { AudiosContentListing } from "./Scenes/Audios/AudiosContentListing";
import { GiftsContentListing } from "./Scenes/Gifts/GiftsContentListing";
import { AudioContentDetail } from "./Scenes/Audios/AudioContentDetail";
import { VideoContentDetail } from "./Scenes/Videos/VideoContentDetail";
import ScrollToTop from "./Components/ScrollToTop";
import { Courses } from "./Scenes/Courses";
import { CourseDetail } from "./Scenes/Courses/CourseDetail";
import { PurchaseGiftDetail } from "./Scenes/Gifts/PurchaseGiftDetail";
import { CourseStep } from "./Scenes/Courses/Steps/CourseStep";
import { MoreMenu } from "./Scenes/MoreMenu";
import { ReceivedGiftsDetail } from "./Scenes/Gifts/ReceivedGiftsDetail";
import { GiftsGivenListing } from "./Scenes/Gifts/GiftsGivenListing";
import { GiftsGivenContentListing } from "./Scenes/Gifts/GiftsGivenContentListing";
import { Events } from "./Scenes/Events";
import { EventContentDetail } from "./Scenes/Events/EventContentDetail";
import { Webcast } from "./Scenes/Webcast";
import { WebcastDetail } from "./Scenes/Webcast/WebcastDetail";
import { WebcastStream } from "./Scenes/Webcast/WebcastStream";
import { useFirebaseAuth } from "./Hooks/Firebase/Auth";
import { BundleDetail } from "./Scenes/Audios/BundleDetail";
import { BundlePlayer } from "./Scenes/Audios/BundlePlayer";
import { isDeviceAllowed } from "JS/Helpers";
import { Box, Typography } from "@mui/material";
import { AppInfo } from "./Scenes/AppInfo";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { useFirebaseLogger } from "./Hooks/Firebase";
import {
    processDownloadQueue,
    resetFirstElementInDownloadQueue,
} from "JS/Helpers/ContentDownloadHelper";
import { PlaylistListing } from "./Scenes/Playlist";
import { PlaylistDetail } from "./Scenes/Playlist/PlaylistDetail";
import { PlaylistPlayer } from "./Scenes/Playlist/PlaylistPlayer";
import { AttachedSku } from "./Scenes/AttachedSku";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { setFailedContent } from "JS/Redux/download";
import { useSnackbar } from "notistack";
import { Feedback } from "./Scenes/Feedback";
import { UserHistory } from "./Scenes/UserHistory";
import { Favorites } from "./Scenes/Favorites";
import { FavoritesAudiosListing } from "./Scenes/Favorites/FavoritesAudiosListing";
import { FavoritesVideosListing } from "./Scenes/Favorites/FavoritesVideosListing";
import { SyncDYS } from "./Scenes/Gifts/SyncDYS";
import { Documents } from "./Scenes/Documents";
import { DocumentDetail } from "./Scenes/Documents/DocumentDetail";
import { useGlobalMedia, useResetZoom, useVerifyAwsKeys } from "./Hooks/Media";
import { Helmet } from "react-helmet";
import { DownloadingQueue } from "./Scenes/DownloadingQueue";
import { FavoritesDocumentsListing } from "./Scenes/Favorites/FavoritesDocumentsListing";
import { Recommendations } from "./Scenes/Recommendations";
import { useSyncFirebaseCourseStats } from "./Hooks/DownloadCourses";
import { useEssentialsInterval } from "./Hooks/MediaEssentials";
import { CommonActions } from "./Container/CommonActions";
import { MiniPlayer } from "./Scenes/MiniPlayer";
import { config } from "JS/Config";
import { Appsettings } from "./Scenes/AppSettings";
import { useRequestPermission } from "./Hooks/Notification";
import { AppConfimationDialog } from "./Components/AppConfirmationDialog";
import { messaging } from "JS/Helpers/UserMessaging";
import { useGlobalPlaylists } from "./Hooks/Playlist";
import {
    getPlaylistMigrated,
    setIsRedirectedToAmmwayConsentForm,
    setPlaylistMigrated,
} from "JS/Helpers/LocalStorageHelpers";
import { GiftDashboard } from "./Scenes/GiftDashboard";
import { useGetLatestUsername } from "./Hooks/Users";
import { useMigrateResumeData } from "./Hooks/Firebase/Resume";
import { useResumeData } from "./Hooks/Firebase/Resume";
import { MixedContent } from "./Scenes/MixedContent";
import { ConsentForm } from "./Scenes/ConsentForm/ConsentForm";
import { RedirectionDialog } from "./Components/RedirectionDialog/RedirectionDialog";
import { getConsentStatusById } from "JS/Database/ConsentForm";
import { openSSOLink } from "JS/Helpers/HomePageHelper";
import { AppBackdropProgress } from "./Components/Progress/AppBackdropProgress";
import { setConsentFormStats, setLoadingState } from "JS/Redux/ConsentForm";
import { FycStatus } from "./Scenes/FycStatus/FycStatus";

library.add(
    faHouse,
    faMusic,
    faVideo,
    faGift,
    faEllipsis,
    faStar,
    faFilledStar,
    faChevronLeft,
    faPlay,
    faPlus,
    faMinus,
    faArrowUp,
    faArrowDown,
    faArrowRight,
    faCreditCard,
    faXmark,
    faDownload,
    faCircleInfo,
    faCircleCheck,
    faPrint,
    faPaperPlane,
    faLock,
    faChevronRight,
    faCameraRotate,
    faFolderClosed,
    faAward,
    faFileLines,
    faMessage,
    faPowerOff,
    faEarthAmericas,
    faAddressCard,
    faFloppyDisk,
    faTrash,
    faPenToSquare,
    faTrash,
    faPen,
    faShuffle,
    faSquareMinus,
    faBars,
    faRepeat,
    faHeadphones,
    faClipboard,
    faFilePdf,
    faFileArrowDown,
    faTv,
    faUpRightFromSquare,
    faForwardStep,
    faBackwardStep,
    faPause,
    faWindowMinimize,
    faWindowMaximize,
    faRotateRight,
    faCogs,
    faSun,
    farSun,
    faMoon,
    farMoon,
    faTablet,
    faTabletAlt,
    faFilter,
    faGlobe,
    faUser,
    faDashboard,
    faIdBadge,
);

export const Root = () => {
    const { routeBuilder } = useRouting();
    const routeProvider = routeBuilder();
    const { isAuthenticated } = useAuth();
    const {
        coursesFirebaseSignIn,
        webCastsFirebaseSignIn,
        loggingFirebaseSignIn,
    } = useFirebaseAuth();
    const { logFirebaseEvent } = useFirebaseLogger();
    const { isZoomAllowed } = useGlobalMedia();
    const {
        logAudioResume,
        logVideoResume,
        logAudioBundleResume,
        logPlaylistResume,
    } = useResumeData();

    useResetZoom();
    useEssentialsInterval();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const downloadContent = useAppSelector((state) => state.download);
    const { syncCourseStats } = useSyncFirebaseCourseStats();
    const { syncUsername } = useGetLatestUsername();
    const { showRedirectionDialog, loadingConsent } = useAppSelector(
        (state) => state.consentForm,
    );

    const { lastFailedContent: failedContent } = downloadContent;
    const deviceAllowed =
        isDeviceAllowed() || config.appEnvironment === "stage";
    const memberId = config.user.memberId;

    useEffect(() => {
        if (failedContent?.nid?.length) {
            enqueueSnackbar(failedContent?.msg, {
                variant: failedContent?.variant,
            });
            dispatch(
                setFailedContent({
                    lastFailedContent: {
                        nid: "",
                        name: "",
                        msg: "",
                    },
                }),
            );
        }
    }, [failedContent?.nid]);

    useEffect(() => {
        if (isAuthenticated) {
            verifyAwsKeys().then(() => {
                resetFirstElementInDownloadQueue();
                processDownloadQueue();
            });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        document.addEventListener("visibilitychange", (e) => {
            const state = document.visibilityState;

            if (state === "visible" && deviceAllowed && isAuthenticated) {
                setIsRedirectedToAmmwayConsentForm("false");
                verifyAwsKeys().then(() => {
                    resetFirstElementInDownloadQueue();
                    processDownloadQueue();
                });
                syncUsername();
                logAudioResume(memberId);
                logVideoResume(memberId);
                logAudioBundleResume(memberId);
                logPlaylistResume(memberId);
            }
        });

        return () =>
            document.removeEventListener("visibilitychange", (e) => {});
    }, [isAuthenticated]);

    const { migratePlaylistData } = useGlobalPlaylists();

    const { migrateResumeToFirestore } = useMigrateResumeData();

    useEffect(() => {
        if (isAuthenticated) {
            coursesFirebaseSignIn()
                .then(async (res) => {
                    syncCourseStats();
                })
                .catch((ex) => console.log("err", ex));
            webCastsFirebaseSignIn();
            loggingFirebaseSignIn();
            setIsRedirectedToAmmwayConsentForm("false");
            const action: FirebaseEventAction = {
                action: EventActions.APPLICATION_LAUNCHED,
                category: EventCategories.GENERAL,
            };
            logFirebaseEvent(EventNames.APPLICATION_LAUNCHED, action);
            if (!getPlaylistMigrated()) {
                migratePlaylistData();
                setPlaylistMigrated();
            }
            syncUsername();
            migrateResumeToFirestore();
        }
    }, [isAuthenticated]);

    const { show, yesClick, noClick } = useRequestPermission();

    const handleClose = () => {
        getConsentStatusById(config.user.memberId).then((consentData) => {
            if (consentData?.redirectToAmwayConsentForm) {
                openSSOLink(consentData.ssoRedirectionPlace, true);
                setIsRedirectedToAmmwayConsentForm("true");

                dispatch(
                    setConsentFormStats({
                        showRedirectionDialog: false,
                        loadingConsent: true,
                    }),
                );

                setTimeout(
                    () => dispatch(setLoadingState({ loadingConsent: false })),
                    3000,
                );

                logFirebaseEvent(EventNames.CONSENT_FORM_V2_REDIRECT, {
                    action: EventActions.CONSENT_FORM_V2_REDIRECT,
                });
            }
        });
    };

    return (
        <React.Fragment>
            {!isZoomAllowed ? (
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
                    />
                </Helmet>
            ) : (
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, user-scalable=1, viewport-fit=cover"
                    />
                </Helmet>
            )}

            <CommonActions />

            <AppConfimationDialog
                open={isAuthenticated && show}
                onConfirm={yesClick}
                onClose={noClick}
                popupMainText={messaging.notification.permission.prompt}
                confirmText={messaging.notification.permission.promptYes}
                cancelButtonText={messaging.notification.permission.promptNo}
                showConfirmFirst={true}
            />

            {isAuthenticated && showRedirectionDialog && (
                <RedirectionDialog
                    open={showRedirectionDialog}
                    onClose={handleClose}
                    dialogMessage={messaging.consentForm.ssoRedirection}
                />
            )}

            {loadingConsent && (
                <AppBackdropProgress
                    open={loadingConsent}
                    backdropText={messaging?.loader?.loadingSession}
                />
            )}

            <Router>
                {deviceAllowed ? (
                    <ScrollToTop>
                        <Route component={MiniPlayer} />

                        <Switch>
                            <Route
                                exact={true}
                                path={routeProvider.react.login()}
                                render={() => {
                                    return isAuthenticated ? (
                                        <Redirect
                                            to={routeProvider.react.home()}
                                        />
                                    ) : (
                                        <Login />
                                    );
                                }}
                            />
                            <Route
                                exact={true}
                                path={routeProvider.react.userHistory()}
                                component={UserHistory}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.audios().listing()}
                                component={AudiosContentListing}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.audios().index()}
                                component={Audios}
                            />
                            <PrivateRoute
                                path={routeProvider.react.audios().detail()}
                                render={(props) => {
                                    return (
                                        <AudioContentDetail
                                            key={window.location.pathname}
                                            {...props}
                                        />
                                    );
                                }}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.videos().index()}
                                component={Videos}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.videos().listing()}
                                component={VideosContentListing}
                            />
                            <PrivateRoute
                                path={routeProvider.react.videos().detail()}
                                component={VideoContentDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.gifts().index()}
                                component={Gifts}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.gifts().listing()}
                                component={GiftsContentListing}
                            />
                            <PrivateRoute
                                path={routeProvider.react.gifts().detail()}
                                component={PurchaseGiftDetail}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .gifts()
                                    .giftAudioDetail()}
                                render={(props) => {
                                    return (
                                        <ReceivedGiftsDetail
                                            key={window.location.pathname}
                                            {...props}
                                        />
                                    );
                                }}
                            />
                            <PrivateRoute
                                path={routeProvider.react.bundle().audios()}
                                component={BundleDetail}
                            />
                            <PrivateRoute
                                path={routeProvider.react.bundle().play()}
                                component={BundlePlayer}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .gifts()
                                    .giftsGivenListing()}
                                component={GiftsGivenListing}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .gifts()
                                    .giftsGivenContentListing()}
                                component={GiftsGivenContentListing}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.home()}
                                component={Home}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.courses().index()}
                                component={Courses}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.courses().detail()}
                                component={CourseDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .gifts()
                                    .courseDetail()}
                                component={CourseDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.more().index()}
                                component={MoreMenu}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .courses()
                                    .mediaStepDetail()}
                                component={CourseStep}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .gifts()
                                    .courseMediaStepDetail()}
                                component={CourseStep}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.events().index()}
                                component={Events}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.events().detail()}
                                component={EventContentDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.fycStatus().index()}
                                component={FycStatus}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.webcast().index()}
                                component={Webcast}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.webcast().detail()}
                                component={WebcastDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.webcast().stream()}
                                component={WebcastStream}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.appInfo().index()}
                                component={AppInfo}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.playlist().index()}
                                component={PlaylistListing}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.playlist().detail()}
                                component={PlaylistDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.playlist().play()}
                                component={PlaylistPlayer}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.attachedSku()}
                                component={AttachedSku}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.feedback().index()}
                                component={Feedback}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react.favorites().index()}
                                component={Favorites}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteAudiosListing()}
                                component={FavoritesAudiosListing}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteVideosListing()}
                                component={FavoritesVideosListing}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteDocumentsListing()}
                                component={FavoritesDocumentsListing}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteVideosDetail()}
                                component={VideoContentDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteAudiosDetail()}
                                component={AudioContentDetail}
                                render={(props) => {
                                    return (
                                        <AudioContentDetail
                                            key={window.location.pathname}
                                            {...props}
                                        />
                                    );
                                }}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteBundleAudios()}
                                component={BundleDetail}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .favorites()
                                    .favoriteBundlePlay()}
                                component={BundlePlayer}
                            />
                            <PrivateRoute
                                path={routeProvider.react.gifts().sync()}
                                component={SyncDYS}
                            />
                            <PrivateRoute
                                path={routeProvider.react.documents().index()}
                                component={Documents}
                            />
                            <PrivateRoute
                                path={routeProvider.react.documents().detail()}
                                component={DocumentDetail}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .downloadingQueue()
                                    .index()}
                                component={DownloadingQueue}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .recommendations()
                                    .index()}
                                component={Recommendations}
                            />
                            <PrivateRoute
                                path={routeProvider.react.appSettings().index()}
                                component={Appsettings}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .giftDashboard()
                                    .index()}
                                component={GiftDashboard}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .mixedContent()
                                    .audioDetail()}
                                render={(props) => {
                                    return (
                                        <AudioContentDetail
                                            key={window.location.pathname}
                                            {...props}
                                        />
                                    );
                                }}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .mixedContent()
                                    .videoDetail()}
                                component={VideoContentDetail}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .mixedContent()
                                    .bundleDetail()}
                                component={BundleDetail}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .mixedContent()
                                    .bundlePlay()}
                                component={BundlePlayer}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .mixedContent()
                                    .courseDetail()}
                                component={CourseDetail}
                            />
                            <PrivateRoute
                                exact={true}
                                path={routeProvider.react
                                    .mixedContent()
                                    .courseMediaStepDetail()}
                                component={CourseStep}
                            />
                            <PrivateRoute
                                path={routeProvider.react
                                    .mixedContent()
                                    .index()}
                                component={MixedContent}
                            />
                            <PrivateRoute
                                path={routeProvider.react.consentForm().index()}
                                component={ConsentForm}
                            />
                            <Route
                                render={() => (
                                    <Redirect
                                        to={routeProvider.react.login()}
                                    />
                                )}
                            />
                        </Switch>
                    </ScrollToTop>
                ) : (
                    <Switch>
                        <Route
                            path={routeProvider.react.root()}
                            render={() => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5">
                                        Not Available
                                    </Typography>
                                </Box>
                            )}
                        />
                    </Switch>
                )}
            </Router>
        </React.Fragment>
    );
};
