import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Grid,
    IconButton,
    Theme,
    InternalStandardProps,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useDocumentPdf, useGlobalDocuments } from "JS/React/Hooks/Documents";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGlobalMedia } from "JS/React/Hooks/Media";
import { useRouting } from "JS/React/Hooks/Routes";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Layout } from "../Layout";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import { config } from "JS/Config";
import { useAppSelector } from "JS/Redux/Store";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import {
    useDeleteDocument,
    useDocumentDownloadedURL,
    useDownloadDocument,
} from "JS/React/Hooks/Database/Document";
import {
    isInDownloadQueue,
    DownloadStatus,
} from "JS/Helpers/ContentDownloadHelper";
import { AppDocument } from "JS/React/Components/AppDocument";
import { isIOS } from "react-device-detect";
import { RenderPrintProps, printPlugin } from "@react-pdf-viewer/print";
import { useDownloadingStatus } from "JS/React/Hooks/Downloading";
import { DownloadDisabled } from "JS/React/Components/DownloadDisabled";
import { DownloadWarningDialog } from "JS/React/Components/DownloadWarningDialog";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
export interface DocumentDetailProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const DocumentDetail = (props: DocumentDetailProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { linkProvider } = useRouting();
    const {
        documentUserFavorites,
        markDocumentsFavorites,
        unMarkDocumentsFavorites,
    } = useGlobalDocuments();
    const { setGlobalMoreStack } = useGlobalNavStack();
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const params: any = useParams();
    const { pdf, isFavoriteTab, description } = params;
    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
    const [deleteLoader] = useState(false);
    const [showLimitDialog, setshowLimitDialog] = useState(false);
    const { logFirebaseEvent } = useFirebaseLogger();

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const {
        pdfWrapperRef,
        documentUrl,
        isDownloaded,
        setIsDocumentDownloaded,
        onDeletePdfInIOS,
    } = useVariables(pdf, downloadedContentNids);
    const { handleDocumentOpened } = useIndexHooks(pdf);

    const [addedToQueue, setAddedToQueue] = useState(false);

    const isDocumentFavorites = () => {
        return documentUserFavorites?.findIndex((x) => x.Name === pdf) !== -1
            ? true
            : false;
    };
    const handleDocumentFavorites = () => {
        if (!isDocumentFavorites()) {
            markDocumentsFavorites({ Name: pdf });

            const action: FirebaseEventAction = {
                action: EventActions.Favorite,
                contentTitle: pdf,
                category: EventCategories.DOCUMENTS,
            };

            logFirebaseEvent(EventNames.DOCUMENT_FAVORITE, action);
        } else {
            unMarkDocumentsFavorites({ Name: pdf });

            const action: FirebaseEventAction = {
                action: EventActions.UnFavorite,
                contentTitle: pdf,
                category: EventCategories.DOCUMENTS,
            };

            logFirebaseEvent(EventNames.DOCUMENT_UNFAVORITE, action);
        }
        enqueueSnackbar(messaging.favorites.success, {
            variant: "success",
        });
    };

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleDeleteSuccess = () => {
        setIsDocumentDownloaded(false);
        if (isIOS) {
            onDeletePdfInIOS();
        }
    };

    const { deleteDocument } = useDeleteDocument();

    const handleDelete = async () => {
        setAddedToQueue(false);
        deleteDocument(pdf, downloadedContentNids, handleDeleteSuccess);
        setIsOpenConfirmDailog(false);
    };
    const toggleLimitDialog = () => {
        setshowLimitDialog(!showLimitDialog);
    };

    const { download } = useDownloadDocument();

    const handleDownload = () => {
        showWarningSnackbarIfRequired();
        const res = download(pdf, description);

        if (res === null) {
            toggleLimitDialog();
        } else {
            setAddedToQueue(true);
        }
    };

    const handleNavigateToQueue = () => {
        const payload = {
            lastVisited: `${history.location.pathname}${history.location.search}`,
        };
        setGlobalMoreStack(payload);
        history.push(linkProvider.react.downloadingQueue().index("Document"));
    };

    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    const { downloadStatus, showWarningSnackbarIfRequired } =
        useDownloadingStatus(pdf, config.s3.documents.baseUrl);

    return (
        <Layout>
            <AppHeader
                title={description}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    isFavoriteTab === "true"
                        ? history.push(
                              linkProvider.react
                                  .favorites()
                                  .favoriteDocumentsListing(),
                          )
                        : history.push(linkProvider.react.documents().index());
                }}
            />
            <div {...rest} className={clsx(className, classes.root)}>
                <Grid container>
                    <Grid item xs={12} className={classes.topNav}>
                        {!isIOS && (
                            <Print>
                                {(props: RenderPrintProps) => (
                                    <IconButton onClick={props.onClick}>
                                        <FontAwesomeIcon
                                            style={{ fontSize: "22px" }}
                                            icon={["fas", "print"]}
                                            className={classes.icon}
                                        />
                                    </IconButton>
                                )}
                            </Print>
                        )}
                        <IconButton>
                            <FontAwesomeIcon
                                onClick={handleDocumentFavorites}
                                style={{ fontSize: "22px" }}
                                icon={[
                                    isDocumentFavorites() ? "fas" : "far",
                                    "star",
                                ]}
                                className={classes.icon}
                            />
                        </IconButton>
                        {!isDownloaded &&
                            !isInDownloadQueue(pdf) &&
                            !addedToQueue &&
                            !(inProgressNid === pdf) && (
                                <IconButton>
                                    {downloadStatus === DownloadStatus.BLOCK ? (
                                        <DownloadDisabled />
                                    ) : (
                                        <DownloadWarningDialog
                                            onConfirm={handleDownload}
                                        >
                                            {(renderProps) => (
                                                <AppDownloadingIcons
                                                    iconType="download"
                                                    onClick={
                                                        renderProps.askConfirmation
                                                    }
                                                />
                                            )}
                                        </DownloadWarningDialog>
                                    )}
                                </IconButton>
                            )}
                        {isDownloaded && (
                            <IconButton>
                                <AppDownloadingIcons
                                    iconType="trash"
                                    onClick={handleToggleConfirmDialog}
                                />
                            </IconButton>
                        )}

                        {!isDownloaded &&
                            (isInDownloadQueue(pdf) || addedToQueue) &&
                            !(inProgressNid === pdf) && (
                                <IconButton>
                                    <AppDownloadingIcons
                                        iconType="queued"
                                        onClick={handleNavigateToQueue}
                                    />
                                </IconButton>
                            )}
                        {inProgressNid === pdf && !isDownloaded && (
                            <IconButton>
                                <AppDownloadingIcons
                                    iconType="in-progress"
                                    onClick={handleNavigateToQueue}
                                />
                            </IconButton>
                        )}
                        <AppConfimationDialog
                            open={isOpenConfirmDailog}
                            onConfirm={() => handleDelete()}
                            btnConfirmLoader={deleteLoader}
                            onClose={handleToggleConfirmDialog}
                            popupMainText="Are you sure you want to delete document?"
                            confirmText="Delete"
                            cancelButtonText="Cancel"
                            disableScrollLock
                            showConfirmFirst={true}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        ref={pdfWrapperRef}
                        className={classes.documentGrid}
                    >
                        {documentUrl && (
                            <AppDocument
                                fileUrl={documentUrl}
                                printPlugin={printPluginInstance}
                                onDocumentOpen={handleDocumentOpened}
                                loader={
                                    <Box display="flex" justifyContent="center">
                                        <AppBackdropProgress
                                            open
                                            backdropText={
                                                messaging.loader.document
                                            }
                                        />
                                    </Box>
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
            {showLimitDialog && (
                <AppConfimationDialog
                    titleText={messaging?.downloadingQueue?.QueueLimitation}
                    showTitle={true}
                    open={showLimitDialog}
                    popupMainText={messaging?.downloadingQueue?.limitReached}
                    cancelButtonText="OK"
                    onClose={toggleLimitDialog}
                />
            )}
        </Layout>
    );
};

const useIndexHooks = (pdfName: string) => {
    const { logFirebaseEvent } = useFirebaseLogger();

    const handleDocumentOpened = () => {
        const action: FirebaseEventAction = {
            action: EventActions.OPEN,
            contentTitle: pdfName,
            category: EventCategories.DOCUMENTS,
        };
        logFirebaseEvent(EventNames.DOCUMENT_OPENED, action);
    };

    return {
        handleDocumentOpened,
    };
};

const useVariables = (pdf: string, downloadedContentNids: string[]) => {
    const pdfWrapperRef = useRef(null);
    const { setIsZoomAllowed } = useGlobalMedia();
    const { pdfUrl } = useDocumentPdf(pdf);
    const [documentUrl, setDocumentUrl] = useState<string>();
    const [isDocumentDownloaded, setIsDocumentDownloaded] =
        useState<boolean>(false);
    const downloadedURLSuccess = () => {
        setIsDocumentDownloaded(true);
    };
    const downloadedURLFailure = () => {
        setIsDocumentDownloaded(false);
        if (!!documentUrl) setDocumentUrl(pdfUrl);
    };
    const { downloadedURL, refetch } = useDocumentDownloadedURL(
        pdf,
        downloadedURLSuccess,
        downloadedURLFailure,
    );

    useEffect(() => {
        setDocumentUrl(!!downloadedURL ? downloadedURL : pdfUrl);
    }, [downloadedURL, pdfUrl]);

    const onDeletePdfInIOS = () => {
        setDocumentUrl(pdfUrl);
    };

    useEffect(() => {
        setIsZoomAllowed(true);

        return () => {
            setIsZoomAllowed(false);
        };
    }, []);

    const isDownloaded =
        isDocumentDownloaded ||
        downloadedContentNids?.includes(`${config.user.memberId}-${pdf}`);

    useEffect(() => {
        if (isDownloaded && isIOS) {
            refetch().then((url) => {
                if (url) {
                    setDocumentUrl(url);
                }
            });
        }
    }, [isDownloaded]);

    return {
        pdfWrapperRef,
        documentUrl,
        isDocumentDownloaded,
        isDownloaded,
        setIsDocumentDownloaded,
        onDeletePdfInIOS,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        topNav: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        documentGrid: {
            overflow: "hidden",
        },
    }),
);
