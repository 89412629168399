import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Box, Card, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import { useParams, useHistory } from "react-router-dom";
import {
    useGlobalGifts,
    useAudioGiftsGiven,
    useVideoGiftsGiven,
} from "JS/React/Hooks/Gifts";
import {
    useGlobalCourses,
    useCourseGiftsGiven,
} from "JS/React/Hooks/Course/Course";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppHeader } from "JS/React/Components/AppHeader";
import sortableHeader from "JS/React/HOC/SortableHeader";
import { ColumnSort, getColumnSort, sortData, Sorting } from "JS/Types";
import { GiftCategories } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AllTabs } from "JS/Models";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grey[500],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
        itemCard: {
            backgroundColor: theme.palette.background.default,
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 15px",
            color: theme.palette.grey[500],
            "&:hover": {
                cursor: "pointer",
            },
        },
        quantityBubble: {
            marginRight: "12px",
            width: "30px",
            height: "30px",
            borderRadius: "100px",
            backgroundColor: theme.palette.grey[200],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        flex: {
            display: "flex",
        },
        alignCenter: {
            alignItems: "center",
        },
        memberId: {
            marginRight: "10px",
        },
    }),
);

const SortableHeader = sortableHeader<HTMLSpanElement>("span");

interface GiftsGivenListingItem {
    count: number;
    receiver: {
        first_name: string;
        last_name: string;
        member_id;
    };
}

export const GiftsGivenListing = (props: any) => {
    const params: any = useParams();
    const { categoryId } = params;
    const Tabs: any = AllTabs;
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { homeStack } = useGlobalNavStack();
    const [filteredGifts, setFilteredGifts] = useState<GiftsGivenListingItem[]>(
        [],
    );

    const [selectedTab, setSelectedTab] = useState<string>(Tabs.AUDIOS);

    const [headerTitle, setHeaderTitle] = useState("");
    const history = useHistory();
    const { linkProvider } = useRouting();

    const { audioGiftsGiven, videoGiftsGiven } = useGlobalGifts();

    const {
        setGlobalGiftsCategoryStack,
        resetGlobalGiftsCategoryStack,
        giftsStack,
    } = useGlobalNavStack();
    // giftsStack[categoryId]?.paginationInfo,

    const [searchQuery, setSearchQuery] = useState<string>(
        giftsStack[categoryId]?.searchQuery,
    );
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 20,
        total: 0,
    });
    const { giftsGiven } = useGlobalCourses();

    const { loading: audioGiftsGivenLoading } = useAudioGiftsGiven();

    const { loading: videoGiftsGivenLoading } = useVideoGiftsGiven();

    const { loading: courseGiftsGivenLoading } = useCourseGiftsGiven();

    const [sorting, setSorting] = useState<Sorting>(
        giftsStack[categoryId]?.sorting,
    );

    const onSortChange = (columnSort: ColumnSort, oldOrder: number) => {
        setSorting([columnSort]);
        setGlobalGiftsCategoryStack(
            {
                sorting: [columnSort],
            },
            categoryId,
        );
    };

    const isLoading = () =>
        (audioGiftsGivenLoading && audioGiftsGiven.length === 0) ||
        videoGiftsGivenLoading ||
        courseGiftsGivenLoading;

    useEffect(() => {
        setHeaderTitle("Gifts Given");
        const uniqueMembers = [];
        audioGiftsGiven?.forEach((agg) => {
            if (
                !uniqueMembers.some(
                    (um) => um.receiver?.member_id === agg.receiver?.member_id,
                )
            ) {
                uniqueMembers.push({
                    receiver: agg.receiver,
                    count: audioGiftsGiven?.filter(
                        (ag) =>
                            ag.receiver?.member_id === agg.receiver?.member_id,
                    )?.length,
                });
            }
        });
        videoGiftsGiven?.forEach((agg) => {
            if (
                !uniqueMembers.some(
                    (um) => um.receiver?.member_id === agg.receiver?.member_id,
                )
            ) {
                if (agg.receiver) {
                    uniqueMembers.push({
                        receiver: agg.receiver,
                        count:
                            audioGiftsGiven?.filter(
                                (ag) =>
                                    ag.receiver?.member_id ===
                                    agg.receiver?.member_id,
                            )?.length || 0,
                    });
                }
            }
        });
        const uniqueMembersCourses = giftsGiven?.map((gg) => ({
            receiver: {
                first_name: gg.receiver_first_name,
                last_name: gg.receiver_last_name,
                member_id: gg.receiver_id,
            },
            count: gg.gift_courses?.length || 0,
        }));
        uniqueMembersCourses?.forEach((umc) => {
            if (
                !uniqueMembers.some(
                    (um) => um.receiver?.member_id === umc.receiver?.member_id,
                )
            )
                uniqueMembers.push(umc);
        });
        setFilteredGifts(uniqueMembers);
    }, [
        audioGiftsGiven,
        videoGiftsGiven,
        giftsGiven,
        categoryId,
        selectedTab,
        Tabs.AUDIOS,
        Tabs.VIDEOS,
        Tabs.COURSES,
    ]);

    const searchResults = useMemo(() => {
        return filteredGifts?.filter(
            (d) =>
                d.receiver?.first_name
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.receiver?.last_name
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.receiver?.member_id
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.count === +searchQuery.trim(),
        );
    }, [filteredGifts, searchQuery]);

    const finalData = useMemo(() => {
        return paginateData(
            sortData(
                searchResults?.map((sr) => ({
                    ...sr.receiver,
                    count: sr.count,
                })),
                sorting,
            ),
            paginationInfo,
        ).data;
    }, [sorting, searchResults, paginationInfo]);

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: searchResults ? searchResults.length : 0,
        });
        setGlobalGiftsCategoryStack(
            {
                searchQuery: searchQuery,
            },
            categoryId,
        );
    }, [searchResults]);

    useEffect(() => {
        if (giftsStack[categoryId]?.scrollPosition === 0) {
            window.scrollTo(0, 500);
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Layout
            paginationInfo={!isLoading() && paginationInfo}
            onLastContentHit={() => {
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 10,
                });
            }}
        >
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Gifts"}
                    canGoBack
                    searchable={categoryId !== GiftCategories.SEND_MEMBER}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    tabs={Tabs}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    searchPlaceholder={`Search Members by Name, LTD ID`}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            setFilteredGifts([]);
                            resetGlobalGiftsCategoryStack(categoryId);
                            resetGlobalGiftsCategoryStack(
                                `${categoryId}-content`,
                            );
                            history.push(linkProvider.react.gifts().index());
                        } else {
                            history.replace(linkProvider.react.home());
                        }
                    }}
                />

                {categoryId !== GiftCategories.SEND_MEMBER &&
                    categoryId !== GiftCategories.RECEIVED_AS_MEMBER &&
                    categoryId !== GiftCategories.RECEIVED_AS_PROSPECT && (
                        <Box
                            display={"flex"}
                            justifyContent={"space-around"}
                            marginBottom={"22px"}
                        >
                            <SortableHeader
                                upDirection="asc"
                                downDirection="desc"
                                sort={getColumnSort(sorting, {
                                    col: "first_name",
                                    position: sorting.length,
                                })}
                                onSortChanged={onSortChange}
                            >
                                <span>A-Z</span>
                            </SortableHeader>
                            <SortableHeader
                                upDirection="asc"
                                downDirection="desc"
                                sort={getColumnSort(sorting, {
                                    col: "member_id",
                                    position: sorting.length,
                                })}
                                onSortChanged={onSortChange}
                            >
                                <span>LTD ID</span>
                            </SortableHeader>
                        </Box>
                    )}

                {!isLoading() &&
                    finalData?.map((filteredGift, idx) => (
                        <Card
                            key={idx}
                            className={clsx(classes.itemCard)}
                            elevation={0}
                            onClick={() => {
                                history.push(
                                    linkProvider.react
                                        .gifts()
                                        .giftsGivenContentListing(
                                            filteredGift?.member_id,
                                            selectedTab,
                                            `${categoryId}-content`,
                                        ),
                                );
                            }}
                        >
                            <Box className={clsx(classes.flex)}>
                                <Typography>
                                    {filteredGift.first_name || ""}{" "}
                                    {filteredGift.last_name || ""}
                                </Typography>
                            </Box>
                            <Box
                                className={clsx(
                                    classes.flex,
                                    classes.alignCenter,
                                )}
                            >
                                <Typography className={clsx(classes.memberId)}>
                                    {filteredGift.member_id || ""}
                                </Typography>
                                <FontAwesomeIcon
                                    icon={["fas", "chevron-right"]}
                                />
                            </Box>
                        </Card>
                    ))}

                {isLoading() && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.content}
                    />
                )}
            </div>
        </Layout>
    );
};
