import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import clsx from "clsx";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import {
    useAudioGifts,
    useVideoGifts,
    useGlobalGifts,
    useSentAudioGifts,
    useSentVideoGifts,
    useSendGifts,
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectAudioGifts,
    useReceivedProspectVideoGifts,
    usePurchasableGiftsAcquiredDates,
} from "JS/React/Hooks/Gifts";
import {
    usePurchasableCourses,
    useGlobalCourses,
    usePurchasedGiftCourses,
    useReceivedCourses,
} from "JS/React/Hooks/Course/Course";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    AppContentListItem,
    AppContentListItemType,
} from "JS/React/Components/AppContentListItem";
import sortableHeader from "JS/React/HOC/SortableHeader";
import {
    ColumnSort,
    getColumnSort,
    sortData,
    Sorting,
    sortViaAcquiredDate,
} from "JS/Types";
import { BottomSheet } from "JS/React/Components/BottomSheet";
import {
    PurchasedGfitCourse,
    PurchasedGiftCourseDetail,
    SendGift,
    Gifts,
    AllTabs,
    Members,
} from "JS/Models";
import { SelectMembersDialog } from "JS/React/Components/ContentPurchase/SelectMembersDialog";
import { AlertDialog } from "JS/React/Components/AlertDialog";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import {
    getDownloadedPartsNids,
    getGiftCategory,
    getPublicUrl,
    isBundleInProgress,
} from "JS/Helpers";
import { useSearchedLosMembers, useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { useGlobalVideos } from "JS/React/Hooks/Video";
import { useAppSelector } from "JS/Redux/Store";
import { isInDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { config } from "JS/Config";
import { messaging } from "JS/Helpers/UserMessaging";
import { Layout } from "../Layout";
import { giftCat, GiftCategories } from ".";

export interface CategoriesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export enum SortableTitle {
    A_Z = "A-Z",
    RELEASED = "Released",
    ACQUIRED = "Acquired",
    PLAYED = "Played",
}

export enum receivedSortableTitle {
    A_Z = "A-Z",
    RELEASED = "Released",
}

const SortableHeader = sortableHeader<HTMLSpanElement>("span");

enum ProsTabs {
    AUDIOS = "Audio",
    VIDEOS = "Video",
}

export const GiftsContentListing = (props: any) => {
    const params: any = useParams();
    const { categoryId, selectedTab } = params;
    const {
        setGlobalGiftsCategoryStack,
        resetGlobalGiftsCategoryStack,
        giftsStack,
    } = useGlobalNavStack();
    const { homeStack } = useGlobalNavStack();
    const { enqueueSnackbar } = useSnackbar();
    const Tabs: any =
        categoryId === GiftCategories.PURCHASABLE_BY_MEMBERS ||
        categoryId === GiftCategories.SEND_MEMBER ||
        categoryId === GiftCategories.RECEIVED_AS_MEMBER
            ? AllTabs
            : ProsTabs;
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const [filteredGifts, setFilteredGifts] = useState([]);
    const [searchQuery, setSearchQuery] = useState<string>(
        giftsStack[categoryId]?.searchQuery
            ? giftsStack[categoryId]?.searchQuery
            : "",
    );
    const [selectMultiple, setSelectMultiple] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<SendGift[]>([]);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] =
        useState<boolean>(false);
    const [sentQuantity, setSentQuantity] = useState<number>(null);

    const [headerTitle, setHeaderTitle] = useState("");

    const history = useHistory();
    const { linkProvider } = useRouting();
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 10,
        total: 0,
    });

    const {
        audioGifts,
        videoGifts,
        sentAudioGifts,
        sentVideoGifts,
        receivedMemberAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectAudioGifts,
        receivedProspectVideoGifts,
        setGlobalGifts,
        audioAcquiredDates,
        videoAcquiredDates,
        courseAcquiredDates,
        prosAudioAcquiredDates,
        prosVideoAcquiredDates,
    } = useGlobalGifts();
    const {
        purchasableCoursesForMembers,
        purchasedGiftCourses,
        receivedCourses,
        setGlobalPurchasedCourses,
    } = useGlobalCourses();

    const { onListItemClick } = useHandlers(categoryId, selectedTab);

    const { favoritesAudios, favoritesVideos } = useGlobalFavorites();

    useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const { getAllPurchasableGiftsAcquiredDates } =
        usePurchasableGiftsAcquiredDates();

    const setSelectedTab = (tab: AllTabs) => {
        history.replace(linkProvider.react.gifts().listing(categoryId, tab));
    };

    useEffect(() => {
        if (
            categoryId === GiftCategories.PURCHASABLE_BY_MEMBERS ||
            categoryId === GiftCategories.PURCHASABLE_BY_PROSPECTS
        ) {
            if (selectedTab === AllTabs.AUDIOS)
                getAllPurchasableGiftsAcquiredDates(
                    giftCat[categoryId].contentItemTypeName?.audio,
                    getHighestSinceDate(),
                );
            else if (selectedTab === AllTabs.VIDEOS)
                getAllPurchasableGiftsAcquiredDates(
                    giftCat[categoryId].contentItemTypeName?.video,
                    getHighestSinceDate(),
                );
            else if (categoryId === GiftCategories.PURCHASABLE_BY_MEMBERS)
                getAllPurchasableGiftsAcquiredDates(
                    giftCat[categoryId].contentItemTypeName?.course,
                    getHighestSinceDate(),
                );
        }
    }, [selectedTab, categoryId]);

    const getHighestSinceDate = () => {
        const sorted = filteredGifts.sort(
            (a, b) => b.acquired_date - a.acquired_date,
        );
        const highest = sorted[0]?.acquired_date;
        if (highest) return highest;
        else return "";
    };

    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { loading: receivedProspectAudioGiftsLoading, isSynced } =
        useReceivedProspectAudioGifts(
            categoryId !== GiftCategories.RECEIVED_AS_PROSPECT ||
                selectedTab !== AllTabs.AUDIOS,
        );
    const { loading: receivedProspectVideoGiftsLoading } =
        useReceivedProspectVideoGifts(
            categoryId !== GiftCategories.RECEIVED_AS_PROSPECT ||
                selectedTab !== AllTabs.VIDEOS,
        );

    const { loading: receivedMemberAudioGiftsLoading } =
        useReceivedMemberAudioGifts(
            categoryId !== GiftCategories.RECEIVED_AS_MEMBER ||
                selectedTab !== AllTabs.AUDIOS,
        );
    const { loading: receivedMemberVideoGiftsLoading } =
        useReceivedMemberVideoGifts(
            categoryId !== GiftCategories.RECEIVED_AS_MEMBER ||
                selectedTab !== AllTabs.VIDEOS,
        );
    const { loading: receivedCoursesLoading } = useReceivedCourses(
        categoryId !== GiftCategories.RECEIVED_AS_MEMBER ||
            selectedTab !== AllTabs.COURSES,
    );

    const { loading: audioLoading } = useAudioGifts(
        (categoryId !== GiftCategories.PURCHASABLE_BY_MEMBERS &&
            categoryId !== GiftCategories.PURCHASABLE_BY_PROSPECTS) ||
            selectedTab !== AllTabs.AUDIOS ||
            (!!audioGifts && audioGifts.length > 0),
    );
    const { loading: videoLoading } = useVideoGifts(
        (categoryId !== GiftCategories.PURCHASABLE_BY_MEMBERS &&
            categoryId !== GiftCategories.PURCHASABLE_BY_PROSPECTS) ||
            selectedTab !== AllTabs.VIDEOS ||
            (!!videoGifts && videoGifts.length > 0),
    );
    const { loading: purchasedCoursesLoading } = usePurchasedGiftCourses(
        categoryId !== GiftCategories.SEND_MEMBER ||
            selectedTab !== AllTabs.COURSES,
    );
    const { loading: courseLoading } = usePurchasableCourses(
        categoryId !== GiftCategories.PURCHASABLE_BY_MEMBERS ||
            selectedTab !== AllTabs.COURSES ||
            (!!purchasableCoursesForMembers &&
                purchasableCoursesForMembers.length > 0),
    );

    const { loading: sentAudioGiftsLoading } = useSentAudioGifts(
        categoryId !== GiftCategories.SEND_MEMBER ||
            selectedTab !== AllTabs.AUDIOS,
    );
    const { loading: sentVideoGiftsLoading } = useSentVideoGifts(
        categoryId !== GiftCategories.SEND_MEMBER ||
            selectedTab !== AllTabs.VIDEOS,
    );
    const { resumeUserBundles, resumeUserAudios } = useGlobalAudios();
    const { resumeUserVideos } = useGlobalVideos();

    const [dialogs, setDialogs] = useState({
        transactionComplete: false,
        selectMembers: false,
    });

    const [selectedMembers, setSelectedMembers] = useState<Members[]>([]);

    const { searchLosResults, searchQueryLos, setSearchQueryLos } =
        useSearchedLosMembers();

    const { sendGiftToUser, loading: sendingGifts } = useSendGifts();

    const [sorting, setSorting] = useState<Sorting>(
        giftsStack[categoryId]?.sorting,
    );
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const onSortChange = (columnSort: ColumnSort, oldOrder: number) => {
        setSorting([columnSort]);
        setGlobalGiftsCategoryStack(
            {
                sorting: [columnSort],
            },
            categoryId,
        );
    };

    const isLoading = () => {
        if (selectedTab === Tabs.AUDIOS) {
            if (categoryId !== GiftCategories.SEND_MEMBER)
                if (categoryId === GiftCategories.RECEIVED_AS_PROSPECT)
                    return (
                        receivedProspectAudioGiftsLoading &&
                        receivedProspectAudioGifts.length === 0
                    );
                else if (categoryId !== GiftCategories.RECEIVED_AS_MEMBER)
                    return audioLoading;
                else
                    return (
                        receivedMemberAudioGiftsLoading &&
                        receivedMemberAudioGifts.length === 0
                    );
            return sentAudioGiftsLoading;
        }
        if (selectedTab === Tabs.VIDEOS) {
            if (categoryId !== GiftCategories.SEND_MEMBER)
                if (categoryId === GiftCategories.RECEIVED_AS_PROSPECT)
                    return (
                        receivedProspectVideoGiftsLoading &&
                        receivedProspectVideoGifts.length === 0
                    );
                else if (categoryId !== GiftCategories.RECEIVED_AS_MEMBER)
                    return videoLoading;
                else
                    return (
                        receivedMemberVideoGiftsLoading &&
                        receivedMemberAudioGifts.length === 0
                    );
            return sentVideoGiftsLoading;
        }
        if (selectedTab === Tabs.COURSES) {
            if (categoryId !== GiftCategories.SEND_MEMBER)
                if (categoryId !== GiftCategories.RECEIVED_AS_MEMBER)
                    return courseLoading;
                else
                    return (
                        receivedCoursesLoading && receivedCourses.length === 0
                    );
            return purchasedCoursesLoading;
        }
    };

    const handleGiftCheckChanged = (nid: string, checked: boolean) => {
        if (checked) {
            const gift = filteredGifts.find((g) => g.nid === nid);
            setSelectedItems((prevState) => [
                ...prevState,
                {
                    nid: gift.nid,
                    sku: gift.sku_id,
                    title: gift.title,
                    category: getGiftCategory(selectedTab),
                },
            ]);
        } else
            setSelectedItems((prevState) =>
                prevState?.filter((item) => item.nid !== nid),
            );
    };

    useEffect(() => {
        setHeaderTitle(giftCat[categoryId].title);
        if (selectedTab === Tabs.AUDIOS) {
            if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
                setFilteredGifts(receivedMemberAudioGifts);
            } else if (categoryId === GiftCategories.RECEIVED_AS_PROSPECT) {
                setFilteredGifts(receivedProspectAudioGifts);
            } else if (categoryId !== GiftCategories.SEND_MEMBER) {
                const toRenderAudioGifts = audioGifts?.filter(
                    (ag) =>
                        ag.content_item_type_name ===
                        giftCat[categoryId].contentItemTypeName?.audio,
                );
                setFilteredGifts(toRenderAudioGifts);
            } else {
                const toRenderAudioGifts = sentAudioGifts?.filter(
                    (ag) => +ag.quantity > 0,
                );
                setFilteredGifts(toRenderAudioGifts);
            }
        } else if (selectedTab === Tabs.VIDEOS) {
            if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
                setFilteredGifts(receivedMemberVideoGifts);
            } else if (categoryId === GiftCategories.RECEIVED_AS_PROSPECT) {
                setFilteredGifts(receivedProspectVideoGifts);
            } else if (categoryId !== GiftCategories.SEND_MEMBER) {
                const toRenderVideoGifts = videoGifts?.filter(
                    (vg) =>
                        vg.content_item_type_name ===
                        giftCat[categoryId].contentItemTypeName?.video,
                );
                setFilteredGifts(toRenderVideoGifts);
            } else {
                const toRenderVideoGifts = sentVideoGifts?.filter(
                    (vg) => +vg.quantity > 0,
                );
                setFilteredGifts(toRenderVideoGifts);
            }
        } else if (selectedTab === Tabs.COURSES) {
            if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
                setFilteredGifts(receivedCourses);
            } else if (categoryId !== GiftCategories.SEND_MEMBER) {
                const toRenderCourseGifts =
                    purchasableCoursesForMembers?.filter(
                        (pcm) =>
                            pcm.content_item_type_name ===
                            giftCat[categoryId].contentItemTypeName?.course,
                    );
                setFilteredGifts(toRenderCourseGifts);
            } else {
                const toRenderSkuIds: { [key: string]: PurchasedGfitCourse } =
                    {};
                const coursesDetails: {
                    [key: string]: PurchasedGiftCourseDetail;
                } = {};
                purchasedGiftCourses?.forEach((pgc) => {
                    if (pgc.quantity > 0)
                        toRenderSkuIds[pgc.sku_id] = {
                            quantity: pgc.quantity,
                            sku_id: pgc.sku_id,
                        };
                });
                purchasableCoursesForMembers?.forEach((pcm) => {
                    if (toRenderSkuIds[pcm.sku_id]) {
                        coursesDetails[pcm.sku_id] = {
                            quantity: toRenderSkuIds[pcm.sku_id].quantity,
                            ...pcm,
                        };
                    }
                });
                const toRenderCourseGifts = Object.values(coursesDetails);
                setFilteredGifts(toRenderCourseGifts);
            }
        }
    }, [
        audioGifts,
        videoGifts,
        sentAudioGifts,
        sentVideoGifts,
        purchasableCoursesForMembers,
        purchasedGiftCourses,
        selectedTab,
        categoryId,
        receivedCourses,
        receivedMemberAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectAudioGifts,
        receivedProspectVideoGifts,
    ]);

    const searchResults = useMemo(() => {
        return filteredGifts?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.description
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [filteredGifts, searchQuery]);

    const acquiredDates =
        useMemo(() => {
            if (selectedTab === AllTabs.AUDIOS) {
                if (categoryId === GiftCategories.PURCHASABLE_BY_MEMBERS)
                    return audioAcquiredDates;
                else return prosAudioAcquiredDates;
            } else if (selectedTab === AllTabs.VIDEOS) {
                if (categoryId === GiftCategories.PURCHASABLE_BY_MEMBERS)
                    return videoAcquiredDates;
                else return prosVideoAcquiredDates;
            } else return courseAcquiredDates;
        }, [
            categoryId,
            selectedTab,
            audioAcquiredDates,
            videoAcquiredDates,
            prosAudioAcquiredDates,
            prosVideoAcquiredDates,
            courseAcquiredDates,
        ]) || {};

    const finalData = useMemo(() => {
        if (sorting[0]?.col === "acquired_date")
            return paginateData(
                sortViaAcquiredDate(
                    searchResults?.map((sr) => ({
                        ...sr,
                        acquired_date: acquiredDates[sr.sku_id],
                    })),
                    sorting,
                ),
                paginationInfo,
            ).data;
        return paginateData(sortData(searchResults, sorting), paginationInfo)
            .data;
    }, [searchResults, sorting, paginationInfo]);

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (gift) => {
        if (gift.content_item_type_name === "GiftCourse") {
            const url = await getPublicUrl(
                gift.image.image_url_prefix,
                gift.image.image_url_postfix,
                gift.image.image_file_name,
            );
            return {
                id: gift.nid,
                url: url,
            };
        } else {
            const url = await getPublicUrl(
                gift.image_url_prefix,
                gift.image_url_postfix,
                gift.image_file_name,
            );
            return {
                id: gift.nid,
                url: url,
            };
        }
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: searchResults ? searchResults.length : 0,
        });
        setGlobalGiftsCategoryStack(
            {
                searchQuery: searchQuery,
            },
            categoryId,
        );
    }, [searchResults]);

    useEffect(() => {
        setSelectedItems([]);
    }, [selectMultiple, selectedTab]);

    const handleSelectMembers = () => {
        setDialogs({
            ...dialogs,
            selectMembers: true,
        });
    };

    const onToggleItemSelect = (value) => {
        if (
            selectedMembers.findIndex(
                (x) => x.member_id === value.member_id,
            ) !== -1
        ) {
            let tempArray = [...selectedMembers];
            tempArray.splice(
                tempArray.findIndex((x) => x.member_id === value.member_id),
                1,
            );
            setSelectedMembers(tempArray);
        } else {
            setSelectedMembers([value]);
        }
    };

    const onCloseSelectMembers = () => {
        setDialogs({
            ...dialogs,
            transactionComplete: false,
            selectMembers: false,
        });
    };

    const updateSentGiftsQuantity = () => {
        if (selectedTab === Tabs.AUDIOS) {
            const updatedSentAudios = sentAudioGifts?.map((sag) => {
                if (!!selectedItems?.find((i) => i.sku === sag.sku_id))
                    return { ...sag, quantity: +sag.quantity - 1 };
                return { ...sag };
            });
            setGlobalGifts({ sentAudioGiftsList: updatedSentAudios });
        } else if (selectedTab === Tabs.VIDEOS) {
            const updatedSentVideos = sentVideoGifts?.map((svg) => {
                if (!!selectedItems?.find((i) => i.sku === svg.sku_id))
                    return { ...svg, quantity: +svg.quantity - 1 };
                return { ...svg };
            });
            setGlobalGifts({ sentVideoGiftsList: updatedSentVideos });
        } else if (selectedTab === Tabs.COURSES) {
            const updatedPurchasedGifts = purchasedGiftCourses?.map((pgc) => {
                if (!!selectedItems?.find((i) => i.sku === pgc.sku_id)) {
                    const quantity: number = +pgc.quantity - 1;
                    return { ...pgc, quantity };
                } else {
                    return { ...pgc };
                }
            });
            setGlobalPurchasedCourses({
                purchasedGiftCourses: updatedPurchasedGifts,
            });
        }
    };

    const onSendSelectedMembers = async () => {
        if (selectedMembers.length === 1) {
            sendGiftToUser(selectedItems, selectedMembers).then((val) => {
                if (val?.response?.status === true) {
                    setSentQuantity(selectedItems?.length);
                    setSelectMultiple(false);
                    setIsSuccessDialogOpen(true);
                    updateSentGiftsQuantity();
                    onCloseSelectMembers();
                }
            });
        } else {
            enqueueSnackbar("Please select at-least one LOS Member.", {
                variant: "warning",
            });
        }
    };

    const handleSuccessDialogClosed = () => {
        setIsSuccessDialogOpen(false);
        setSelectedItems([]);
        setSelectedMembers([]);
        setSentQuantity(null);
    };

    let contentItemType: AppContentListItemType = "purchasableCourses";
    if (categoryId === GiftCategories.SEND_MEMBER) contentItemType = "gift";
    else if (
        categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
        categoryId === GiftCategories.RECEIVED_AS_PROSPECT
    )
        contentItemType = "receivedGift";

    const getCurrentTabData = () => {
        if (selectedTab === Tabs.AUDIOS) {
            if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
                return receivedMemberAudioGifts;
            } else if (categoryId === GiftCategories.RECEIVED_AS_PROSPECT) {
                return receivedProspectAudioGifts;
            } else if (categoryId !== GiftCategories.SEND_MEMBER) {
                return audioGifts?.filter(
                    (ag) =>
                        ag.content_item_type_name ===
                        giftCat[categoryId].contentItemTypeName?.audio,
                );
            } else {
                return sentAudioGifts?.filter((ag) => +ag.quantity > 0);
            }
        } else if (selectedTab === Tabs.VIDEOS) {
            if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
                return receivedMemberVideoGifts;
            } else if (categoryId === GiftCategories.RECEIVED_AS_PROSPECT) {
                return receivedProspectVideoGifts;
            } else if (categoryId !== GiftCategories.SEND_MEMBER) {
                return videoGifts?.filter(
                    (vg) =>
                        vg.content_item_type_name ===
                        giftCat[categoryId].contentItemTypeName?.video,
                );
            } else {
                return sentVideoGifts?.filter((vg) => +vg.quantity > 0);
            }
        } else if (selectedTab === Tabs.COURSES) {
            if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
                return receivedCourses;
            } else if (categoryId !== GiftCategories.SEND_MEMBER) {
                return purchasableCoursesForMembers?.filter(
                    (pcm) =>
                        pcm.content_item_type_name ===
                        giftCat[categoryId].contentItemTypeName?.course,
                );
            } else {
                const toRenderSkuIds: { [key: string]: PurchasedGfitCourse } =
                    {};
                const coursesDetails: {
                    [key: string]: PurchasedGiftCourseDetail;
                } = {};
                purchasedGiftCourses?.forEach((pgc) => {
                    if (pgc.quantity > 0)
                        toRenderSkuIds[pgc.sku_id] = {
                            quantity: pgc.quantity,
                            sku_id: pgc.sku_id,
                        };
                });
                purchasableCoursesForMembers?.forEach((pcm) => {
                    if (toRenderSkuIds[pcm.sku_id]) {
                        coursesDetails[pcm.sku_id] = {
                            quantity: toRenderSkuIds[pcm.sku_id].quantity,
                            ...pcm,
                        };
                    }
                });
                return Object.values(coursesDetails);
            }
        }
    };

    const isResumeable = (filteredGift: Gifts) => {
        if (
            categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
            categoryId === GiftCategories.RECEIVED_AS_PROSPECT
        ) {
            if (selectedTab === AllTabs.AUDIOS) {
                if (filteredGift?.media?.length > 1) {
                    let filterResumeBundle =
                        resumeUserBundles &&
                        resumeUserBundles.filter(
                            (x) => x.nid === filteredGift.nid,
                        );
                    if (filterResumeBundle.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (
                        resumeUserAudios &&
                        resumeUserAudios.filter(
                            (x) => x.nid === filteredGift.nid,
                        ).length > 0
                    ) {
                        const resumeData = resumeUserAudios.find(
                            (x) => x.nid === filteredGift.nid,
                        );
                        return !!resumeData;
                    } else {
                        return false;
                    }
                }
            } else {
                if (
                    resumeUserVideos &&
                    resumeUserVideos.filter((x) => x.nid === filteredGift.nid)
                        .length > 0
                ) {
                    const resumeData = resumeUserVideos.find(
                        (x) => x.nid === filteredGift.nid,
                    );
                    return !!resumeData;
                } else {
                    return false;
                }
            }
        }
    };

    const isFavouriteContent = (filteredGift: Gifts) => {
        if (
            categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
            categoryId === GiftCategories.RECEIVED_AS_PROSPECT
        ) {
            if (selectedTab === AllTabs.AUDIOS) {
                if (
                    favoritesAudios &&
                    favoritesAudios.find((x) => x.nid === filteredGift.nid)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (
                    favoritesVideos &&
                    favoritesVideos.find((x) => x.nid === filteredGift.nid)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 500);
        window.scrollTo(0, 0);
    }, [isLoading()]);

    useEffect(() => {
        if (isSynced && !isSynced.synced)
            history.replace(linkProvider.react.gifts().sync());
    }, [isSynced]);

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const inProgressDownloaded = useCallback(
        (nid: string, length) => {
            if (downloadedContentNids) {
                if (length > 1) {
                    const downloadedIds = downloadedContentNids.filter(
                        (downloadedNid) =>
                            downloadedNid.startsWith(
                                `${config.user.memberId}-${nid}`,
                            ),
                    );
                    return downloadedIds?.length === length;
                } else {
                    return downloadedContentNids?.includes(
                        `${config.user.memberId}-${nid}`,
                    );
                }
            } else {
                return false;
            }
        },
        [downloadedContentNids],
    );

    const isAudioDownloaded = async (
        nid: string,
        type: string,
        length?: number | undefined,
    ) => {
        if (length && length > 1) {
            const downloadedPartsNids = await getDownloadedPartsNids(nid);

            return downloadedPartsNids?.length === length;
        } else {
            const db = getDexieConnectionRefresh();
            const activeDb =
                type === "GiftVideo" || type === "GiftProsVideo"
                    ? db.videos
                    : db.audios;
            const response = await activeDb.get(
                `${config.user.memberId}-${nid}`,
            );

            return response ? true : false;
        }
    };
    const isDownloading = (nid: string, length: number) => {
        if (length > 1) return isBundleInProgress(nid);
        else return inProgressNid?.includes(nid);
    };

    const isAutoPlay = selectedTab === AllTabs.VIDEOS ? "true" : "false";

    return (
        <Layout
            paginationInfo={!isLoading() && paginationInfo}
            onLastContentHit={() => {
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 10,
                });
                setGlobalGiftsCategoryStack(
                    {
                        searchQuery: searchQuery,
                    },
                    categoryId,
                );
            }}
        >
            <div
                className={clsx(
                    className,
                    classes.root,
                    selectMultiple && classes.bottomSheetPadding,
                )}
                {...rest}
            >
                <AppHeader
                    title={headerTitle || "Gifts"}
                    canGoBack
                    searchable={true}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    tabs={Tabs}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    showTabs={!dialogs?.selectMembers}
                    searchPlaceholder={`Search ${selectedTab}s by title or description`}
                    onBackClick={() => {
                        resetGlobalGiftsCategoryStack(categoryId);
                        if (!homeStack?.isHomeTrack) {
                            setFilteredGifts([]);
                            history.push(linkProvider.react.gifts().index());
                        } else {
                            history.replace(linkProvider.react.home());
                        }
                    }}
                />
                {!dialogs?.selectMembers && (
                    <BottomSheet open={selectMultiple}>
                        <Button
                            disabled={selectedItems.length <= 0}
                            onClick={handleSelectMembers}
                            className={clsx(classes.bottomSheetBtn)}
                        >
                            SEND
                        </Button>
                    </BottomSheet>
                )}

                {categoryId !== GiftCategories.SEND_MEMBER &&
                    categoryId !== GiftCategories.RECEIVED_AS_MEMBER &&
                    categoryId !== GiftCategories.RECEIVED_AS_PROSPECT && (
                        <Box
                            display={"flex"}
                            justifyContent={"space-around"}
                            marginBottom={"22px"}
                        >
                            <SortableHeader
                                upDirection="asc"
                                downDirection="desc"
                                sort={getColumnSort(sorting, {
                                    col: "title",
                                    position: sorting.length,
                                })}
                                onSortChanged={onSortChange}
                            >
                                <span>A-Z</span>
                            </SortableHeader>
                            <SortableHeader
                                upDirection="desc"
                                downDirection="asc"
                                sort={getColumnSort(sorting, {
                                    col: "release_date",
                                    position: sorting.length,
                                })}
                                onSortChanged={onSortChange}
                            >
                                <span>Released</span>
                            </SortableHeader>
                            <SortableHeader
                                upDirection="desc"
                                downDirection="asc"
                                sort={getColumnSort(sorting, {
                                    col: "acquired_date",
                                    position: sorting.length,
                                })}
                                onSortChanged={onSortChange}
                            >
                                <span>Acquired</span>
                            </SortableHeader>
                        </Box>
                    )}
                {categoryId === GiftCategories.SEND_MEMBER &&
                    !dialogs?.selectMembers && (
                        <Box className={clsx(classes.sendCheckboxContainer)}>
                            <FormControlLabel
                                onChange={() =>
                                    setSelectMultiple((prevState) => !prevState)
                                }
                                className={clsx(classes.checkboxControlLabel)}
                                control={
                                    <Checkbox
                                        checked={selectMultiple}
                                        icon={
                                            <RadioButtonUnchecked
                                                className={clsx(
                                                    classes.checkedIcon,
                                                )}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckCircleOutline
                                                className={clsx(
                                                    classes.checkedIcon,
                                                )}
                                            />
                                        }
                                    />
                                }
                                label="Send Multiple Gifts"
                            />
                        </Box>
                    )}
                {!isLoading() &&
                    finalData?.map((filteredGift, idx) => (
                        <AppContentListItem
                            nid={filteredGift.nid}
                            isDownloadInProgress={
                                isDownloading(
                                    filteredGift?.nid,
                                    filteredGift?.media?.length,
                                ) &&
                                !inProgressDownloaded(
                                    filteredGift?.nid,
                                    filteredGift?.media?.length,
                                )
                            }
                            isInDownloadQueue={
                                isInDownloadQueue(filteredGift.nid) &&
                                !isDownloading(
                                    filteredGift?.nid,
                                    filteredGift?.media?.length,
                                )
                            }
                            isDownloaded={isAudioDownloaded(
                                filteredGift.nid,
                                filteredGift.content_item_type_name,
                                filteredGift?.media?.length,
                            )}
                            inProgressDownloaded={inProgressDownloaded(
                                filteredGift.nid,
                                filteredGift?.media?.length,
                            )}
                            type={contentItemType}
                            selectMode={selectMultiple}
                            sender={filteredGift.sender}
                            key={idx}
                            title={filteredGift.title}
                            showPlayIcon={
                                (categoryId ===
                                    GiftCategories.RECEIVED_AS_MEMBER ||
                                    categoryId ===
                                        GiftCategories.RECEIVED_AS_PROSPECT) &&
                                !filteredGift.content_item_type_name.includes(
                                    "Course",
                                )
                            }
                            releaseDate={filteredGift.release_date}
                            acquiredDate={filteredGift.acquired_date}
                            sortType={sorting[0].col}
                            purchasedQuantity={
                                filteredGift.quantity > 99
                                    ? "99+"
                                    : filteredGift.quantity
                            }
                            contentPrice={filteredGift.content_price}
                            isRedeemableByToken={
                                filteredGift.is_redeemable_by_tokens
                            }
                            numberOfTokens={filteredGift.number_of_tokens}
                            skuId={filteredGift.sku_id}
                            onGiftCheckChanged={handleGiftCheckChanged}
                            giftChecked={
                                !!selectedItems?.find(
                                    (s) => s.sku === filteredGift.sku_id,
                                )
                            }
                            isResume={isResumeable(filteredGift)}
                            description={filteredGift.description}
                            showFavIcon={
                                !filteredGift.content_item_type_name.includes(
                                    "Course",
                                ) && isFavouriteContent(filteredGift)
                            }
                            isFavourite={
                                !filteredGift.content_item_type_name.includes(
                                    "Course",
                                ) && isFavouriteContent(filteredGift)
                            }
                            imageUrl={
                                thumbnails && thumbnails[filteredGift.nid]
                            }
                            isAllowClick
                            onListItemClick={() => {
                                onListItemClick(filteredGift, isAutoPlay);
                            }}
                            onItemPlayClick={() => {
                                onListItemClick(filteredGift, isAutoPlay);
                            }}
                        />
                    ))}

                {!isLoading() && dialogs?.selectMembers && (
                    <SelectMembersDialog
                        open={true}
                        searchable={true}
                        searchPlaceholder="Search by name or LTD ID"
                        searchQuery={searchQueryLos}
                        setSearchQuery={setSearchQueryLos}
                        selectedMembers={selectedMembers}
                        checkboxList={searchLosResults}
                        onToggleItemSelect={onToggleItemSelect}
                        onSend={onSendSelectedMembers}
                        onClose={onCloseSelectMembers}
                        giftSendLoading={sendingGifts}
                        singleSelect
                    />
                )}

                {isLoading() && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={`Fetching Gifts`}
                    />
                )}
            </div>
            {isSuccessDialogOpen && (
                <AlertDialog fixed onClose={handleSuccessDialogClosed}>
                    <Typography className={clsx(classes.dialogTypography)}>
                        Send Complete
                    </Typography>
                    <Typography className={clsx(classes.dialogTypography)}>
                        {sentQuantity || ""} {selectedTab} file
                        {sentQuantity > 1 ? "s were" : " was"} sent to{" "}
                        {selectedMembers[0]?.first_name || ""}{" "}
                        {selectedMembers[0]?.last_name || ""}
                    </Typography>
                </AlertDialog>
            )}
            {!isLoading() && getCurrentTabData()?.length === 0 && (
                <Typography
                    align="center"
                    variant="h6"
                    className={classes.content}
                >
                    {messaging.common.noItemsFound}
                </Typography>
            )}
        </Layout>
    );
};

const useHandlers = (categoryId: string, selectedTab: AllTabs) => {
    const history = useHistory();
    const { linkProvider } = useRouting();

    const onListItemClick = (gift: Gifts, isAutoPlay: string) => {
        if (
            categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
            categoryId === GiftCategories.RECEIVED_AS_PROSPECT
        ) {
            if (selectedTab === AllTabs.COURSES)
                history.push(
                    linkProvider.react
                        .gifts()
                        .courseDetail(gift.nid, "true", "true"),
                );
            else if (
                selectedTab === AllTabs.AUDIOS ||
                selectedTab === AllTabs.VIDEOS
            )
                history.push(
                    linkProvider.react
                        .gifts()
                        .giftAudioDetail(
                            gift.nid,
                            categoryId,
                            selectedTab,
                            isAutoPlay,
                        ),
                );
        } else {
            history.push(
                linkProvider.react
                    .gifts()
                    .detail(gift.nid, categoryId, selectedTab),
            );
        }
    };

    return {
        onListItemClick,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
        sendCheckboxContainer: {
            textAlign: "center",
            margin: "15px 0",
        },
        checkboxControlLabel: {
            "& .MuiTypography-body1": {
                color: `${theme.palette.grey[700]} !important`,
                fontSize: "18px",
            },
        },
        bottomSheetBtn: {
            height: `${theme.footer.height}`,
            width: "100%",
            paddingTop: "0",
            color: theme.palette.grey[600],
            borderTop: `0.2px solid ${theme.palette.grey[300]}`,
            "&.Mui-disabled": {
                color: theme.palette.grey[300],
            },
        },
        checkedIcon: {
            color: theme.palette.grey[700],
        },
        bottomSheetPadding: {
            paddingBottom: "120px",
        },
        dialog: {
            width: "90vw",
        },
        closeBtn: {
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
        },
        dialogContent: {
            marginTop: "20px",
        },
        dialogTypography: {
            textAlign: "center",
            color: theme.palette.grey[600],
            marginBottom: "10px",
        },
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
    }),
);
