import { Box, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { config } from "JS/Config";
import { Tabs } from "JS/Helpers/FycStatusConstants";

export interface userTabsProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    isSecondaryActive: boolean;
    handleTabSwitch: (userId: string) => void;
    hasSpouse: boolean;
}

export const UserTabs = (props: userTabsProps) => {
    const classes = useStyles(props);
    const { isSecondaryActive, handleTabSwitch, hasSpouse } = props;

    const memberId = config?.user?.memberId;
    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            className={classes.tabContainer}
        >
            <Box
                key={memberId}
                onClick={() => handleTabSwitch(config?.user?.memberId)}
                className={clsx(
                    classes.tab,
                    hasSpouse && classes.tabBorder,
                    hasSpouse && classes.marginRight,
                    !isSecondaryActive && classes.activeTab,
                )}
            >
                {Tabs.PRIMARY_USER}
            </Box>
            {hasSpouse && (
                <Box
                    key={memberId + "2"}
                    onClick={() =>
                        handleTabSwitch(config?.user?.memberId + "2")
                    }
                    className={clsx(
                        classes.tab,
                        classes.tabBorder,
                        classes.marginLeft,
                        isSecondaryActive && classes.activeTab,
                    )}
                >
                    {Tabs.SECONDARY_USER}
                </Box>
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabContainer: {
            marginBottom: "40px",
        },
        tab: {
            cursor: "pointer",
            fontSize: "1rem",
            color: theme.palette.grey[400],
            fontWeight: 500,
        },
        tabBorder: {
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: "5px",
            padding: "10px 15px",
        },
        activeTab: {
            color: theme.colors.white,
            backgroundColor: theme.palette.grey[900],
            border: `2px solid ${theme.palette.grey[900]}`,
        },
        marginRight: {
            marginRight: "5px",
        },
        marginLeft: {
            marginLeft: "5px",
        },
    }),
);
