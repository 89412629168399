import { IndexableTypeArray, IndexableTypePart } from "dexie";
import { config } from "JS/Config";
import { getAudioById } from "JS/Database/Audio";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { AudioContent, Gifts } from "JS/Models";
import { getDownloadQueueFromStorage } from "./ContentDownloadHelper";

export const getDownloadedPartsNids = async (bundleNid: string) => {
    const bundleCollection = getDexieConnectionRefresh().audios.filter(
        (audio) => audio.isBundle === true,
    );
    let downloadedPartsNids: string[] = [];

    await bundleCollection.keys().then((res) => {
        if (res && res.length) {
            const downloadedBundlePartsNids: IndexableTypeArray = res;
            downloadedBundlePartsNids.forEach((nid: IndexableTypePart) => {
                if (
                    nid
                        ?.toString()
                        ?.includes(`${config.user.memberId}-${bundleNid}`)
                ) {
                    downloadedPartsNids.push(nid?.toString());
                }
            });
        }
    });

    return downloadedPartsNids;
};

export const isBundleInProgress = (nid: string) => {
    const downloadQueue = getDownloadQueueFromStorage();
    let isInQueue = false;
    for (const item of downloadQueue) {
        if (item.nid.includes(nid)) {
            isInQueue = true;
            break;
        }
    }

    return isInQueue;
};

export const isPlaylistDownloaded = async (
    audios: (AudioContent | Gifts)[],
): Promise<{
    isPartiallyDownloded: boolean;
    isCompleteDownloaded: boolean;
}> => {
    let isPartiallyDownloded = false;
    let isCompleteDownloaded = false;
    const totalItems = audios?.length;

    if (totalItems) {
        const downlaodedAudios = await getDownloadedAudios(audios);
        const downloadedItems = downlaodedAudios?.length;
        if (downloadedItems === totalItems) isCompleteDownloaded = true;
        else if (downloadedItems !== 0) isPartiallyDownloded = true;
    }

    return { isCompleteDownloaded, isPartiallyDownloded };
};

export const getDownloadedAudios = async (
    audios: (AudioContent | Gifts)[],
): Promise<(AudioContent | Gifts)[]> => {
    const downloadedAudios: (AudioContent | Gifts)[] = [];
    const promises: Promise<void>[] = [];
    for (const audio of audios) {
        if (audio?.media?.length > 1) {
            const prom = getDownloadedPartsNids(audio?.nid).then((res) => {
                if (res.length === audio?.media?.length) {
                    downloadedAudios.push(audio);
                }
            });

            promises.push(prom);
        } else {
            const prom = getAudioById(
                `${config.user.memberId}-${audio?.nid}`,
            ).then((res) => {
                if (res) {
                    downloadedAudios.push(audio);
                }
            });

            promises.push(prom);
        }
    }

    await Promise.all(promises);

    return downloadedAudios;
};
