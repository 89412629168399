import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import moment from "moment";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";

import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";

import { AppHeader } from "JS/React/Components/AppHeader";
import {
    FBCourseStats,
    FBStepStats,
    StepContentType,
} from "JS/Models/Firebase/Courses";
import qs from "qs";
import { useRouting } from "JS/React/Hooks/Routes";
import { OfflineMediaStep } from "./OfflineMediaStep";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { EventActions, EventCategories, EventNames } from "JS/Models";
import {
    useDownloadedCourses,
    useGetLatestCourseStats,
    useGetLatestStepStats,
    useWriteOfflineCourseStats,
    useWriteOfflineStepStats,
} from "JS/React/Hooks/DownloadCourses";
import { OfflineQuizStep } from "./OfflineQuizStep";
import { useStepFromDb } from "JS/React/Hooks/Database/Course";
import { config } from "JS/Config";
import cloneDeep from "lodash/cloneDeep";
import { createCourseIDBId } from "JS/Helpers";
import { PdfStep } from "JS/React/Scenes/Courses/Steps/PdfStep";
import {
    useCourseCompletion,
    useReceivedCourses,
} from "JS/React/Hooks/Course/Course";
import { useScrollToTop } from "JS/React/Hooks/Media";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { SentGiftType, toSentGift } from "JS/Models/Firebase/GiftDashboard";

export const OfflineCourseStep = (props: OfflineCourseStepProps) => {
    const classes = useStyles(props);

    const history = useHistory();
    const location = useLocation();
    const { linkProvider } = useRouting();

    const { detailSkuId, stepSkuId, isAutoPlay }: any = useParams();

    const [currentPlayingState, setCurrentPlayingState] = useState(true);

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { displayMode = "", segment = "" } = parsedQueryString;

        return {
            displayMode: displayMode ? displayMode.toString() : null,
            segment: segment ? segment.toString() : null,
        };
    }, [location?.search]);

    const displayMode = queryParams.displayMode === "true";
    const { userDownloadedCourses } = useDownloadedCourses();
    const { writeOfflineFBCourseStats } = useWriteOfflineCourseStats();
    const { writeOfflineFBStepStats } = useWriteOfflineStepStats();

    const { getLatestCourseStats } = useGetLatestCourseStats();
    const { getLatestStepsStats } = useGetLatestStepStats();

    const [forceStatsUpdate, setForceStatsUpdate] = useState(false);

    const activeCourse = useMemo(() => {
        return userDownloadedCourses?.find((c) => c.sku_id === detailSkuId);
    }, [userDownloadedCourses, detailSkuId]);

    const step = activeCourse?.steps.find((d) => d.sku_id === stepSkuId);

    const { downloadedContent } = useStepFromDb(
        createCourseIDBId(config.user.memberId, activeCourse?.nid, step?.nid),
    );

    useScrollToTop();

    const stepStats = useMemo(
        () => cloneDeep(getLatestStepsStats(detailSkuId, stepSkuId)),
        [detailSkuId, getLatestStepsStats, stepSkuId, forceStatsUpdate],
    );

    const { logFirebaseEvent } = useFirebaseLogger();

    const { updateCourseCompletion } = useCourseCompletion(
        activeCourse?.content_item_type_name,
    );

    const nextStep = activeCourse?.steps.find(
        (d) => +d.position === +step.position + 1 && d?.isDownloaded === true,
    );

    const prevStep = activeCourse?.steps.find(
        (d) => +d.position === +step.position - 1 && d?.isDownloaded === true,
    );

    const { receivedCourses } = useReceivedCourses(true);

    const receivedCourse = useMemo(() => {
        return receivedCourses?.find((c) => c.sku_id === detailSkuId);
    }, [receivedCourses, detailSkuId]);

    const { logGiftOpen, logGiftCompleted } = useGiftDashboard(
        toSentGift(receivedCourse),
        SentGiftType.COURSE,
        receivedCourse?.sender?.member_id,
        false,
        undefined,
        true,
    );

    const onStepOpen = async () => {
        if (step) {
            if (activeCourse?.content_item_type_name === "Course") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.OPEN,
                    category: EventCategories.COURSE_STEP,
                    eventNames: EventNames.COURSE_STEP_OPENED,
                });
            }
            if (activeCourse?.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.OPEN,
                    category: EventCategories.GIFT_COURSE_STEP,
                    eventNames: EventNames.GIFT_COURSE_STEP_OPENED,
                });
                logGiftOpen({ stepSku: stepSkuId, stepName: step.title });
            }

            let latestStepStats = cloneDeep(
                getLatestStepsStats(detailSkuId, stepSkuId),
            );
            const latestCourseStats = cloneDeep(
                getLatestCourseStats(detailSkuId),
            );

            if (latestCourseStats) {
                const courseStatsToUpdate: FBCourseStats = {
                    ...latestCourseStats,
                    analytics: {
                        ...latestCourseStats.analytics,
                        last_step_started: step.sku_id,
                        last_step_start_date: moment().valueOf(),
                    },
                };

                if (latestCourseStats.analytics.first_start_date === 0) {
                    courseStatsToUpdate.analytics.first_start_date =
                        moment().valueOf();
                    courseStatsToUpdate.analytics.progress_state = 1;
                }

                updateCourseStats(courseStatsToUpdate);
            }

            if (latestStepStats) {
                const statsToUpdate: FBStepStats = {
                    ...latestStepStats,
                    analytics: {
                        ...latestStepStats.analytics,
                        last_opened: moment().valueOf(),
                        opened_count:
                            latestStepStats.analytics.opened_count + 1,
                    },
                };

                if (latestStepStats.analytics.first_start_date === 0) {
                    statsToUpdate.analytics.first_start_date =
                        moment().valueOf();
                }

                if (step.content_item_type_name === "PDF") {
                    statsToUpdate.analytics.last_start_date =
                        moment().valueOf();
                }

                if (
                    step.content_item_type_name === "quiz" &&
                    statsToUpdate.analytics.completed_count < 1
                ) {
                    statsToUpdate.analytics.last_start_date =
                        moment().valueOf();
                }

                if (
                    !latestStepStats.quiz &&
                    step.content_item_type_name === "quiz"
                ) {
                    statsToUpdate.quiz = {
                        result: {
                            correct_answers: 0,
                            total_questions: step.quiz.length,
                            wrong_answers: 0,
                        },
                        type: activeCourse.content_item_type_name,
                        content_type: StepContentType.Quiz,
                    };
                }

                updateStepStats(statsToUpdate);
            }
        }
    };

    const updateStepStats = (stats: FBStepStats) => {
        writeOfflineFBStepStats(detailSkuId, stepSkuId, stats);
        setForceStatsUpdate(!forceStatsUpdate);
    };

    const updateCourseStats = async (stats: FBCourseStats) => {
        writeOfflineFBCourseStats(detailSkuId, stats);
    };

    const handleLogFirebaseEvents = (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => {
        if (type === "course") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: activeCourse.title,
                nId: activeCourse.nid,
                skuId: activeCourse.sku_id,
                category: settings.category,
            });
        }
        if (type === "step") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: step.title,
                nId: activeCourse.nid,
                skuId: activeCourse.sku_id,
                stepNId: step.nid,
                stepSkuId: step.sku_id,
                position: +step.position,
                category: settings.category,
            });
        }
        if (type === "question") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: step.title,
                nId: activeCourse.nid,
                skuId: activeCourse.sku_id,
                stepNId: step.nid,
                stepSkuId: step.sku_id,
                position: +step.position,
                category: settings.category,
                questionId: settings.questionId,
                questionPosition: settings.questionPos,
            });
        }
    };

    // stats related functions

    const onStepCompleted = async () => {
        if (activeCourse?.content_item_type_name === "Course") {
            handleLogFirebaseEvents("step", {
                action: EventActions.COMPLETED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_COMPLETED,
            });
        }
        if (activeCourse?.content_item_type_name === "GiftCourse") {
            handleLogFirebaseEvents("step", {
                action: EventActions.COMPLETED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_COMPLETED,
            });
            logGiftCompleted({ stepSku: stepSkuId, stepName: step.title });
        }
        const latestStepStats = cloneDeep(
            getLatestStepsStats(detailSkuId, stepSkuId),
        );

        if (latestStepStats) {
            if (!latestStepStats.analytics.first_completed_date) {
                latestStepStats.analytics.first_completed_date =
                    moment().valueOf();
            }
            updateStepStats({
                ...latestStepStats,
                analytics: {
                    ...latestStepStats.analytics,
                    completed_count:
                        latestStepStats.analytics.completed_count + 1,
                    last_completed_date: moment().valueOf(),
                    progress_state: 2,
                    last_played: 0,
                    maximum_played: 0,
                    completed_percentage: 100,
                },
            });
        }

        updateCourseCompletion(
            cloneDeep(getLatestCourseStats(detailSkuId)),
            updateCourseStats,
            handleLogFirebaseEvents,
        );

        setForceStatsUpdate(!forceStatsUpdate);

        if (step.content_item_type_name === "quiz") {
            if (activeCourse.content_item_type_name === "Course") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.QUIZ_COMPLETED,
                    category: EventCategories.COURSE_STEP,
                    eventNames: EventNames.COURSE_STEP_QUIZ_COMPLETED,
                });
            }
            if (activeCourse.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.QUIZ_COMPLETED,
                    category: EventCategories.GIFT_COURSE_STEP,
                    eventNames: EventNames.GIFT_COURSE_STEP_QUIZ_COMPLETED,
                });
            }
        } else {
            onStepFinished();
        }
    };

    const onStepFinished = () => {
        if (displayMode && nextStep && step?.content_item_type_name !== "PDF") {
            forwardStep();
        } else {
            if (step?.content_item_type_name === "quiz") {
                goBackToIndex();
            }
        }
    };

    const forwardStep = () => {
        if (nextStep) {
            setCurrentPlayingState(true);
            history.push(
                linkProvider.react
                    .offline()
                    .courses()
                    .mediaStepDetail(
                        activeCourse.sku_id,
                        nextStep.sku_id,
                        isAutoPlay,
                        displayMode,
                    ),
            );
        }
    };

    const backwardStep = () => {
        if (prevStep) {
            setCurrentPlayingState(true);
            history.push(
                linkProvider.react
                    .offline()
                    .courses()
                    .mediaStepDetail(
                        activeCourse.sku_id,
                        prevStep.sku_id,
                        isAutoPlay,
                        displayMode,
                    ),
            );
        }
    };

    const goBackToIndex = () => {
        history.push(
            linkProvider.react.offline().courses().detail(activeCourse?.nid),
        );
    };

    const mediaURL = useMemo(() => {
        return downloadedContent?.blob
            ? URL.createObjectURL(downloadedContent?.blob)
            : "";
    }, [downloadedContent?.id]);

    return (
        <Box className={classes.disabledLayoutRoot}>
            <AppHeader
                title={step?.title}
                canGoBack
                searchable={false}
                onBackClick={goBackToIndex}
            />
            {displayMode && step?.content_item_type_name !== "quiz" && (
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                        >
                            {!!prevStep ? (
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className={classes.forwardBackItem}
                                    onClick={backwardStep}
                                >
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.fwdBackIcon,
                                            classes.backIcon,
                                        )}
                                        icon={["fas", "chevron-left"]}
                                    />
                                    <Typography
                                        className={classes.fwdBackTypo}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        Back
                                    </Typography>
                                </Box>
                            ) : (
                                <div></div>
                            )}

                            {!!nextStep &&
                            stepStats?.analytics?.completed_count &&
                            stepStats?.analytics?.completed_count > 0 ? (
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className={classes.forwardBackItem}
                                    onClick={forwardStep}
                                >
                                    <Typography
                                        className={classes.fwdBackTypo}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        Next
                                    </Typography>
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.fwdBackIcon,
                                            classes.fwdIcon,
                                        )}
                                        icon={["fas", "chevron-right"]}
                                    />
                                </Box>
                            ) : (
                                <div></div>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}

            {step && activeCourse && (
                <>
                    {(step.content_item_type_name === "Video" ||
                        step.content_item_type_name === "MP3") && (
                        <OfflineMediaStep
                            key={step?.sku_id}
                            step={step}
                            mediaUrl={mediaURL}
                            imgUrl={""}
                            updateStepStats={updateStepStats}
                            latestStepStats={stepStats}
                            courseSku={detailSkuId}
                            stepSku={stepSkuId}
                            detail={activeCourse}
                            onStepCompleted={onStepCompleted}
                            onStepOpen={onStepOpen}
                            handleLogFirebaseEvents={handleLogFirebaseEvents}
                            isAutoPlay={isAutoPlay === "true"}
                            setCurrentPlayingState={setCurrentPlayingState}
                            currentPlayingState={currentPlayingState}
                            receivedCourse={receivedCourse}
                        />
                    )}

                    {step.content_item_type_name === "PDF" && (
                        <PdfStep
                            course={activeCourse}
                            mediaUrl={mediaURL}
                            onStepCompleted={onStepCompleted}
                            onStepOpen={onStepOpen}
                            onClose={goBackToIndex}
                            handleLogFirebaseEvents={handleLogFirebaseEvents}
                        />
                    )}
                    {step.content_item_type_name === "quiz" && (
                        <OfflineQuizStep
                            key={step?.sku_id}
                            course={activeCourse}
                            courseSku={detailSkuId}
                            goBackToIndex={goBackToIndex}
                            updateStepStats={updateStepStats}
                            step={step}
                            onQuizStepCompleted={onStepCompleted}
                            onQuizStepFinished={onStepFinished}
                            onStepOpen={onStepOpen}
                            handleLogFirebaseEvents={handleLogFirebaseEvents}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },
        spinner: {
            color: theme.palette.primary.main,
        },

        loaderWrapper: {
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },

        forwardBackItem: {
            padding: theme.spacing(2),
            color: theme.palette.grey.A100,
            cursor: "pointer",
        },
        fwdBackTypo: {
            fontSize: "18px",
        },
        fwdBackIcon: {
            fontSize: "18px",
        },
        backIcon: {
            marginRight: theme.spacing(2),
        },
        fwdIcon: {
            marginLeft: theme.spacing(2),
        },

        disabledLayoutRoot: {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
        },
    }),
);

export type CourseLogFirebaseEventSettings = {
    eventNames: EventNames;
    action: EventActions;
    category: EventCategories;
    questionId?: number;
    questionPos?: number;
};

export interface OfflineCourseStepProps
    extends InternalStandardProps<GridProps> {}
